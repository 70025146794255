//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'swiper';
//@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;

	section{
		overflow: hidden;
	}
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

// .breadcrumb-con{
// 	@include font-size(18);
// 	line-height: 1.2em;
// 	margin-bottom: 50px;
// 	color: $colour;
// 	font-weight: 400;
// 	display: flex;
// 	justify-content: flex-start;

// 	@media (max-width: $b3){
// 		margin-bottom: 30px;
// 	}

// 	span{
// 		color: white;
// 		@include font-size(18);
// 		line-height: 1.2em;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}

// 	a{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		color: $red;
// 		font-weight: 400;
// 		margin-right: .2em;
// 		position: relative;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}

// 		&:after{
// 			content: '/';
// 			display: inline-block;
// 			margin-left: .4em;
// 			color: $colour;
// 		}


// 		&:hover,
// 		&:focus-visible{
// 			color: $red;
// 			text-decoration: none;
// 		}
// 	}

// 	> a{
// 		margin-left: 0;
// 	}

// 	.breadcrumb_last{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		color: $colour;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		font-weight: 400;
// 		position: relative;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}
// }

//
//!! BREADCRUM END
//

// .pagination-con{
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: flex-start;
// }

// .pagination.pagination.pagination{
// 	margin-bottom: 0;
// 	vertical-align: middle;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	margin-top: 4.25em;

// 	@media (max-width: $b3){
// 		margin-top: 1.25em;
// 	}

// 	.pagination-mid{
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 	}

// 	div{
// 		text-align: center;
// 		margin: 0 7px;
// 		@include font-size(20);
// 		line-height: 28px;
// 		position: relative;
// 		z-index: 1;


// 		&:not(.prev):not(.next){

// 			&.active{
// 				pointer-events: none;

// 				a{
// 					color: $teal;
// 				}
// 			}

// 			span,
// 			a{
// 				@include font-size(20);
// 				line-height: 28px;
// 				display: block;
// 				color: $blue-medium;
// 				font-weight: 300;
// 				padding: 0;

// 				&:hover,
// 				&:focus-visible{
// 					text-decoration: none;
// 					color: $teal;
// 				}
// 			}


// 			&.ellipse{
// 				color: $colour;
// 				font-weight: 600;
// 			}
// 		}

// 		&.next,
// 		&.prev{

// 			a{
// 				@include font-size(0);
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				color: $white;
// 				text-decoration: none;
// 				background-position: 50% 37%;
// 				background-repeat: no-repeat;
// 				height: 28px;
// 				width: 14px;

// 				&:hover,
// 				&:focus-visible{
// 					text-decoration: none;
// 				}
// 			}
// 		}

// 		&.next{
			
// 			a{
// 				background-image: url(../images/arrow-right.svg);
// 				background-position: 52% 50%;

// 				&:hover,
// 				&:focus-visible{
// 					background-image: url(../images/arrow-right-teal.svg);
// 				}
// 			}
// 		}

// 		&.prev{
			
// 			a{
// 				background-image: url(../images/arrow-left.svg);
// 				background-position: 48% 50%;

// 				&:hover,
// 				&:focus-visible{
// 					background-image: url(../images/arrow-left-teal.svg);
// 				}
// 			}
// 		}
// 	}
// }

//
//!!SOCIAL MENU START
//
$social-size:		20;
$social-addition:	18;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$instagram:		#8a3ab9;
$youtube:		#bb0000;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		border-radius: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $white;
		text-decoration: none;
		padding: 0;
		font-weight: 100;
		margin: 0 16px 16px 0;
		border: 1px solid white;

		@media (max-width: $b3){
			@include font-size(16);
			line-height: 38px;
			height: 38px;
			min-width: 38px;
			border-radius: 38px;
			margin: 0 10px;
		}

		&:last-of-type{
			margin-right: 0;
		}
		
		&:hover,
		&:focus-visible{
			
			i{
				color: $blue!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;

			@media (max-width: $b3){
				@include font-size(16);
				line-height: 38px;
				min-width: 38px;
			}
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}

	
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}

		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.back-dots{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 50%;
		left: -17.8%;
		width: 69.4%;
		height: 100%;
		background-size: 100% auto;
		background-image: url(../images/back-dots.svg);
		background-repeat: no-repeat;
		transform: translateY(-50%);
		opacity: .02;
		pointer-events: none;
		background-position: 0 100%;
	}

	.container{
		position: relative;
		z-index: 1;
	}
}

.back-circle{
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 50% 50%;
	position: absolute;
	border-radius: 50%;

	@media (max-width: $b3){
		opacity: .75;
	}

	.center-b{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(1);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 50% 50%;
		width: 100%;
		height: 100%;
	}
	
	&.c-o{
		background-image: url(../images/c-o.svg);

		.center-o{
			background-image: url(../images/c-i-o.svg);
		}
	}

	&.c-b{
		background-image: url(../images/c-b.svg);
	}

	&.c-g{
		background-image: url(../images/c-g.svg);

		.center-g{
			background-image: url(../images/c-i-g.svg);
		}
	}

	&.c-y{
		background-image: url(../images/c-y.svg);
	}

	&.c-r{
		background-image: url(../images/c-r.svg);

		.center-r{
			background-image: url(../images/c-i-r.svg);
		}
	}

	&.c-bl{
		background-image: url(../images/c-bl.svg);

		.center-b{
			background-image: url(../images/c-i-b.svg);
		}
	}
}

.hero-into-sections-section{

	.back-circle.c-bl.active{
		transition: $time $time;
	}
	
	&.hide-first{

		.back-circle.c-bl.active{
			opacity: 0;
			visibility: hidden;
			transition: $time;
		}
	}

	.back-circle{
		will-change: transform;

		&.c-o{
			width: 375.6115px;
			height: 375.6115px;
			bottom: -16.6%;
			left: -8.25%;
			bottom: 23.4%;
			left: -48.25%;
			transform: translate(139%, 94.6%);
			filter: blur(4px);
			animation: blurM2 (random(4) + 1)+s (random(3) + 1)+s infinite linear;

			@media (max-width: 1440px){
				width: 295.6115px;
				height: 295.6115px;
				bottom: 43.4%;
				left: -35.25%;
			}

		}
	
		&.c-b{
			width: 292.3381px;
			height: 292.3381px;
			bottom: 14.6%;
			left: 26.65%;
			bottom: -5.4%;
			left: -43.35%;
			transform: translate(312.7%, -60.7%);
			filter: blur(5px);
			animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
		}
	
		&.c-g{
			width: 244.3643px;
			height: 244.3643px;
			bottom: 54.1%;
			left: 56.05%;
			bottom: 114.1%;
			left: 16.05%;
			transform: translate(213.9%, 218.3%);
			filter: blur(6px);
			animation: blurM1 (random(4) + 1)+s (random(3) + 1)+s infinite linear;
		}
	
		&.c-y{
			width: 215.3057px;
			height: 215.3057px;
			bottom: 61.5%;
			left: 89.05%;
			bottom: 71.5%;
			left: 119.05%;
			transform: translate(-181.6%, 41.4%);
			filter: blur(10px);
			animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
		}
	
		&.c-r{
			width: 275.5064px;
			height: 275.5064px;
			bottom: -20.9%;
			left: 45.15%;
			bottom: 79.1%;
			left: 65.15%;
			transform: translate(-94.6%, 322.6%);
			filter: blur(4px);
			animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
		}

		&.c-bl{
			width: 607px;
			height: 607px;
			bottom: -86%;
			bottom: calc(0px - 50vh - 303.5px);
			left: 50.85%;
			transform: translate(51.3%, -98.7%) scale(0.845);
			filter: blur(14px);

			@media (max-width: 1310px){
				width: 400px;
				height: 400px;
				bottom: -75%;
				bottom: calc(0px - 50vh - 200px);
			}

			@media (max-width: 950px){
				width: 335px;
				height: 335px;
				bottom: -69%;
			}

			@media (max-width: $b3){
				margin: 2em auto;
				width: 400px;
				height: 400px;
				bottom: -100.8%;
				bottom: -504px;
				left: calc(50.35% - 200px);

			}

			@media (max-width: $b4){
				width: 268px;
				height: 268px;
				bottom: -93.1%;
				bottom: -100vh;
				bottom: -372px;
				left: calc(50.35% - 134px);
			}

			.center-b{
				transform: translate(-50%, -50%) scale(0);
			}

			&.active .center-b{
				// // Particle setting
				// $particle-count: 60;
				// $particle-size: 1rem;
				// $particle-distance: 100vh;
				// $particle-min-speed: 5000;  // ms
				// $particle-max-speed: 15000; // ms
				// $particle-max-delay: 12000; // ms

				// .bottom-particles{
				// 	position: absolute;
				// 	bottom: 6.4%;
				// 	left: 6.4%;
				// 	width: 87.2%;
				// 	height: 87.2%;
				// 	overflow: hidden;
				// 	border-radius: 50%;
				// 	clip-path: circle(50% at 50% 50%);

				// 	.bubble{
				// 		opacity:0;
				// 		position: absolute;
				// 		bottom: -$particle-size;
				// 		width: $particle-size;
				// 		height: $particle-size;
				// 		background-color:rgba($white ,0.5);
				// 		border-radius:50%;
						
				// 		// Randomize bubles
				// 		@for $i from 1 through $particle-count{
						
				// 			// random animation speed
				// 			$speed: random($particle-max-speed) + 0ms;
				// 			@if $speed < $particle-min-speed{
				// 				$speed: $particle-min-speed + 0ms;
				// 			}
								
				// 			&:nth-child(#{$i}){
				// 				left: random(100) + 0%;
				// 				animation: blow $speed infinite;
				// 				animation-delay: random($particle-max-delay) + 0ms;
				// 			}
				// 		}
				// 	}
				// }

				// @keyframes blow {
				// 	0% {
				// 		opacity: 0;
				// 		transform:translate(0, 0);
				// 	}
				// 	20% { 
				// 		opacity: 1;
				// 	}
				// 	100% {
				// 		opacity: 0;
				// 		transform:translate(0, -$particle-distance) scale(.2);
				// 	}
				// }


				.container-middle {
					position: absolute;
					height: 100;
					width: 100;
					animation: rotate1 20s infinite;
					clip-path: circle(50% at 50% 50%);
					bottom: 6.4%;
					left: 6.4%;
					width: 87.2%;
					height: 87.2%;
					overflow: hidden;
				}

				.circle1 {
					position:absolute;
					left:0;
					top:0;
					width:50%;
					height:50%;
					background-color: red;
					border-radius:50%;
					opacity:0.7;
					animation: move1 5s infinite;
				}
				  
				.circle2 {
					position:absolute;
					right:0px;
					top:0px;
					width:50%;
					height:50%;
					background-color:yellow;
					border-radius:50%;
					opacity:0.7;
					animation: move2 5s infinite;
				}
				.circle3{
					position:absolute;
					left:0px;
					bottom:0px;
					background-color:blue;
					height:50%;
					width:50%;
					border-radius:50%;
					opacity:0.7;
					animation: move3 5s infinite;
				}

				.circle4 {
					position:absolute;
					right:0px;
					bottom:0px;
					height:50%;
					width:50%;
					background-color:green;
					border-radius:50%;
					opacity:0.7;
					animation: move4 5s infinite;
				}

				.circle1,
				.circle2,
				.circle3,
				.circle4{
					background-color: transparent;
					border: 3px solid white;
				}
				
				@keyframes move1 {
					0% {
						left:0px;
						top:0px;
					}
					50% {
						left:25%;
						top:25%;
					}
				}
				  
				@keyframes move2 {
					0% {
						top:0;
						right:0;
					}
					50% {
						top:25%;
						right:25%;
					}
				}
				  
				@keyframes move3 {
					0% {
						bottom:0;
						left:0;
					}
					50% {
						bottom:25%;
						left:25%;
					}
				}
				@keyframes move4  {
					0% {
						bottom:0;
						right:0;
					}
					50% {
						bottom:25%;
						right:25%;
					}
				}
				  
				@keyframes rotate1 {
					0% {
						transform: rotate(0deg);
					}
					25%{
						transform: rotate(90deg);
					}
					50%{
						transform: rotate(180deg);
					}
					75%{
						transform: rotate(270deg);
					}
					100%{
						transform: rotate(360deg);
					}
				}
			}
		}

		@keyframes blurM1 {
			0%,
			100%{
				filter: blur(4px);
				transform: translate(213.9%, 218.3%);
			}

			50%{
				filter: blur(14px);
				transform: translate(213.9%, 248.3%);
			}
		}

		@keyframes blurM2 {
			0%,
			100%{
				filter: blur(4px);
				transform: translate(139%, 94.6%);
			}

			50%{
				filter: blur(14px);
				transform: translate(139%, 114.6%);
			}
		}

		@keyframes blur1 {
			0%,
			100%{
				filter: blur(4px);
			}

			50%{
				filter: blur(14px);
			}
		}

		@keyframes blur2 {
			0%,
			100%{
				filter: blur(2px);
			}

			50%{
				filter: blur(15px);
			}
		}

		@keyframes blur3 {
			0%,
			100%{
				filter: blur(6px);
			}

			50%{
				filter: blur(16px);
			}
		}

		@keyframes blur4 {
			0%,
			100%{
				filter: blur(3px);
			}

			50%{
				filter: blur(14px);
			}
		}

		@keyframes blur5 {
			0%,
			100%{
				filter: blur(3px);
			}

			50%{
				filter: blur(15px);
			}
		}
	}

	
	.container{
		position: relative;
	}

	.container.top .row{
		min-height: 100vh;
		align-items: center;
		padding-top: 160px;
		padding-bottom: 188px;
	}

	.header-slide{

		// .swiper-wrapper{
		// 	transition-duration: 0ms;
		// }

		.swiper-slide{

			.inner{
				transform: translateY(100%);
				opacity: 0;
				visibility: hidden;
			}

			&.swiper-slide-active{

				.inner{
					transform: translateY(0);
					transition: $time*2;
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.scroll{
		text-transform: uppercase;
		@include font-size(19);
		position: absolute;
		bottom: 48px;
		left: 41px;
		font-weight: 700;
		font-family: $alt-font;
		letter-spacing: .09em;

		@media (max-width: $b2){
			@include font-size(16);
		}
	
		@media (max-width: $b3){
			@include font-size(13);
		}
	}

	h1{
		width: 880px;
		margin-bottom: 0;
		max-width: 100%!important;
	}

	.bottom-con-con{
		transition: $time;
		position: relative;

		.side-nav-con{
			position: absolute;
			left: 82px;
			height: 100%;
			width: 14px;

			@media (max-width: 1200px){
				left: 35px;
			}
	
			@media (max-width: $b3){
				display: none;
			}
		}

		.side-nav{
			position: absolute;
			top: calc(50vh - (128px/2));
			z-index: 2;

			&.bottom{
				top: auto;
				bottom: calc(50vh - (128px/2));
			}
			
			&.fixed{
				position: fixed;
				top: 50%;
				transform: translateY(-50%);
			}
			
			&:after{
				content: '';
				position: absolute;
				top: 24px;
				bottom: 24px;
				left: 50%;
				width: 1px;
				background-color: white;
				transform: translateX(-50%);
				z-index: -1;
			}
			
			a{
				display: block;
				width: 20px;
				height: 20px;
				margin: 24px auto;
				position: relative;
				background-color: white;
				width: 14px;
				height: 14px;
				border-radius: 14px;

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 6px;
					height: 6px;
					border-radius: 3px;
					background-color: black;
					transition: .2s;
					opacity: 0;
				}

				&:hover{

					&:after{
						background-color: $blue;
					}
				}

				&.active{

					&:after{
						opacity: 1;
					}
				}
			}
		}
	}

	.bottom-con{
		transition: $time;
		opacity: 0;
		visibility: hidden;

		&.active{
			opacity: 1;
			visibility: visible;
		}
		
		.container.bottom{
			position: static;
			top: 0;
		}

		.circle-side{
			padding-right: 36px;
			padding-left: 10px;

			@media (max-width: $b3){
				padding-right: 26px;
				padding-left: 26px;
			}

			@media (max-width: b3){
				min-height: 464px;
			}

			@media (max-width: $b4){
				min-height: 332px;
			}
		}

		.side-c{
			width: 607px;
			height: 607px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 50% 50%;

			@media (max-width: 1310px){
				width: 400px;
				height: 400px;
			}

			@media (max-width: 950px){
				width: 335px;
				height: 335px;
			}

			@media (max-width: $b3){
				margin: 2em auto;
				width: 400px;
				height: 400px;
			}

			@media (max-width: $b4){
				width: 268px;
				height: 268px;
			}

			&.c-g-c{
				background-image: url(../images/c-g-c.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				position: relative;

				&::after{
					content: '';
					background-image: url(../images/c-g-c-i.svg);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					position: absolute;
					top: 0%;
					left: 0%;
					width: 100%;
					height: 100%;
					overflow: hidden;
					border-radius: 50%;
					background-size: 100% 100%;
				}
			}

			&.c-o-w{
				background-image: url(../images/c-o-w.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				position: relative;

				$stars: 120;
				
				.c-o-w-i{
					position: absolute;
					bottom: 6.4%;
					left: 6.4%;
					width: 87.2%;
					height: 87.2%;
					overflow: hidden;
					border-radius: 50%;
					clip-path: circle(50% at 50% 50%);
				}

				.center{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 0;
					height: 0;
					perspective: 75px;
					transform-style: preserve-3d;
				}

				.con{
					width: calc((607px / 2) - 6.4%);
					max-width: calc((607px / 2) - 6.4%);
					height: .1px;
					transform-origin: 0% 0%;
					position: absolute;
					top: 50%;
					left: 50%;

					@media (min-width: $b3 + 1){
						
						.safari &{
							height: 1px;
						}
					}

					@media (max-width: 1310px){
						width: calc((400px / 2) - 6.4%);
						max-width: calc((400px / 2) - 6.4%);
					}

					@media (max-width: 950px){
						width: calc((335px / 2) - 6.4%);
						max-width: calc((335px / 2) - 6.4%);
					}

					@media (max-width: $b3){
						height: 1px;

						.safari &{
							height: 1.3px;
						}
					}

					&:after{
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: 0;
						height: 100%;
						background-color: $white;
						background-color: rgba($white, .5);
						border-radius: 10px;
					}
				}
			}

			&.c-r-p{
				background-image: url(../images/c-r-p.svg);
				background-repeat: no-repeat;
				position: relative;

				&::after{
					content: '';
					background-image: url(../images/c-r-p-i.svg);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					position: absolute;
					top: 0%;
					left: 0%;
					width: 100%;
					height: 100%;
					overflow: hidden;
					border-radius: 50%;
					background-size: 100% 100%;
				}

				.c-r-p-i{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: 100%;
					transform: translate(-50%,-50%) scale(1);
					z-index: 1;

					&:before,
					&:after{
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: 100%;
						transform: translate(-50%,-50%);
						width: 42%;
						height: 42%;
						border: 2px solid white;
						border-radius: 50%;
						box-shadow: 0 0 11px white, inset 0 0 11px white, 0 0 75px rgba(black,.2), inset 0 0 75px rgba(black,.2);
						filter: blur(1px);
					}

					&:after{
						width: 72%;
						height: 72%;
					}

					.center{
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: 100%;
						transform: translate(-50%,-50%);
						width: 24%;
						height: 24%;
						background-image: url(../images/c-r-p-i-c.svg);
						background-repeat: no-repeat;
						background-position: 50% 50%;
						background-size: 100% 100%;
						border-radius: 50%;
						box-shadow: 0 0 50px rgba(black,.2);
						filter: blur(5px);
					}
				}
				
			}

			&.c-r-bl{
				background-image: url(../images/c-bl-alt.svg);
				background-repeat: no-repeat;
				position: relative;
			}
		}

		&.section3.active{

			// .side-c{

			// 	&::after{
			// 		animation: rotate 10s 0s infinite linear;
			// 	}
			// }

			// @keyframes rotate {
			// 	0%,
			// 	100%{
			// 		transform: rotate(0deg);
			// 	}

			// 	99.999%{
			// 		transform: rotate(359.9999deg);
			// 	}
			// }

			.side-c{
				width: 607px;
				height: 607px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 50% 50%;

				@media (max-width: 1310px){
					width: 400px;
					height: 400px;
				}

				@media (max-width: 950px){
					width: 335px;
					height: 335px;
				}

				@media (max-width: $b3){
					margin: 2em auto;
					width: 400px;
					height: 400px;
				}
	
				@media (max-width: $b4){
					width: 268px;
					height: 268px;
				}
		
				&.c-o-w{
					$stars: 60;
					
					@for $i from 0 through $stars{
	
						.con:nth-child(#{$i}){
							transform: rotate(random(360)+deg) rotateY(85deg) rotateZ(180deg);
	
							&:after{
								width: random(30) + 60 + px;
								max-width: 0px;
								animation: stars 4500ms (random(3500) + 1500 + ms) infinite ease-in;	
							}
						}
					}
	
					@keyframes stars {
						0%{
							left: 0%;
							max-width: 0px;
							opacity: 1;
						}

						50%{
							left: 50%;
							max-width: 20px;
							opacity: 1;
						}
	
						100%{
							left: 100%;
							max-width: 90px;
							opacity: .1;
						}
					}
				}
			}
		}

		&.section2.active{

			.side-c{

				.c-r-p-i{
					animation: pulse 9s 0s infinite;

					@keyframes pulse{
						0%,
						100%{
							transform: translate(-50%,-50%) scale(1);
						}

						33.33%{
							transform: translate(-50%,-50%) scale(.9);
						}

						50%{
							transform: translate(-50%,-50%) scale(1.05);
						}

						80%{
							transform: translate(-50%,-50%) scale(.8);
						}
					}
				}
			}
		}

		&.section4.active{

			.side-c{

				&::after{
					animation: rotate 10s 0s infinite linear;
				}
			}

			@keyframes rotate {
				0%,
				100%{
					transform: rotate(0deg);
				}

				99.999%{
					transform: rotate(359.9999deg);
				}
			}
		}

		&.section5.active{

			// Particle setting
			$particle-count: 60;
			$particle-size: 1rem;
			$particle-distance: 100vh;
			$particle-min-speed: 5000;  // ms
			$particle-max-speed: 15000; // ms
			$particle-max-delay: 12000; // ms

			.bottom-particles{
				position: absolute;
				bottom: 6.4%;
				left: 6.4%;
				width: 87.2%;
				height: 87.2%;
				overflow: hidden;
				border-radius: 50%;
				clip-path: circle(50% at 50% 50%);

				.bubble{
					opacity:0;
					position: absolute;
					bottom: -$particle-size;
					width: $particle-size;
					height: $particle-size;
					background-color:rgba($white ,0.5);
					border-radius:50%;
					
					// Randomize bubles
					@for $i from 1 through $particle-count{
					
						// random animation speed
						$speed: random($particle-max-speed) + 0ms;
						@if $speed < $particle-min-speed{
							$speed: $particle-min-speed + 0ms;
						}
							
						&:nth-child(#{$i}){
							left: random(100) + 0%;
							animation: blow $speed infinite;
							animation-delay: random($particle-max-delay) + 0ms;
						}
					}
				}
			}

			@keyframes blow {
				0% {
					opacity: 0;
					transform:translate(0, 0);
				}
				20% { 
					opacity: 1;
				}
				100% {
					opacity: 0;
					transform:translate(0, -$particle-distance) scale(.2);
				}
			}

			.side-c.c-r-bl{


			}
			
		}
	}

	.container.bottom .row{
		min-height: 100vh;
		padding-top: 160px;
		padding-bottom: 188px;
		align-items: center;

		@media (max-width: $b3){
			min-height: 0vh;
			padding-top: 140px;
			padding-bottom: 0px;
		}
	}

	.bottom-con-con{

		.bottom-con{

			@media (max-width: $b3){

				.bottom{

					.row{
						padding-top: 40px;
					}
				}

				&:last-of-type{

					.bottom{

						.row{
							padding-bottom: 80px;
						}
					}
				}
				
				
			}
		}
	}

	.text-side{
		padding-left: 142px;

		@media (max-width: 1200px){
			padding-left: 80px;
		}

		@media (max-width: $b3){
			padding-left: 26px;
			text-align: center;
			order: 2;
		}
	}

	.btn{
		margin-top: 1em;
	}
}

.text-offset-under-con{
	position: relative;

	.back-con-con{
		position: absolute;
		top: -20%;
		bottom: -20%;
		left: 0;
		width: 100%;
		overflow: hidden;
		pointer-events: none;
	}

	.back-con{
		position: absolute;
		top: 0%;
		bottom: 0%;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 1306px;

		.back-circle{

			&.c-g{
				width: 244.3643px;
				height: 244.3643px;
				top: 11.5%;
				left: 2.15%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-o{
				width: 375.6115px;
				height: 375.6115px;
				top: 6.1%;
				left: 85.75%;
				filter: blur(4px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-y{
				width: 215.3057px;
				height: 215.3057px;
				top: 29.8%;
				left: -11.6%;
				filter: blur(10px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		
			&.c-b{
				width: 292.3381px;
				height: 292.3381px;
				top: 31.6%;
				left: 93.65%;
				filter: blur(5px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

}

.text-offset-under-section{
	padding-top: 221px;
	text-align: center;
	padding-bottom: 250px;
	position: relative;

	img{
		margin: 0 auto 1.5em;
	}

	h2{
		margin-bottom: .52em;
	}

	.text{
		margin: 0 auto;
		width: 780px;
	}

	.line{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 5px;
		height: 210px;

		@media (max-width: $b3){
			height: 200px;
		}

		&:before{
			content: '';
			position: absolute;
			top: 0;
			width: 1px;
			left: 50%;
			transform: translateX(-50%);
			background-color: white;
			height: 100%;
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			width: 5px;
			max-width: 5px;
			left: 50%;
			transform: translate(-50%, 0px);
			background-color: white;
			height: 94px;
			animation: scrollL 2s 0s infinite ease-in;

			@media (max-width: $b3){
				animation: scrollLM 2s 0s infinite ease-in;
			}

			@keyframes scrollL{
				0%,
				10%,
				100%{
					transform: translate(-50%, 0px);
				}

				85%,
				99.9999%{
					transform: translate(-50%, 210px);
				}
			} 

			

			@keyframes scrollLM{
				0%,
				10%,
				100%{
					transform: translate(-50%, 0px);
				}

				85%,
				99.9999%{
					transform: translate(-50%, 200px);
				}
			} 
		}
	}
}

.text-offset-under-under-section{
	padding-top: 230px;
	padding-bottom: 202px;

	@media (max-width: $b3){
		padding-top: 80px;
		padding-bottom: 40px;
		text-align: center;
	}

	h2{
		border-bottom: 1px solid white;
		padding-bottom: .55em;
		margin-bottom: .96em;
	}

	.col-lg-4{
		margin-bottom: 30px;

		@media (max-width: $b3){
			margin-bottom: 3em;
		}

		.top{
			display: flex;
			flex-wrap: wrap;
			position: relative;
			align-items: center;
			margin-bottom: 1.3em;

			@media (max-width: $b3){
				justify-content: center;
			}

			img{
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);

				@media (max-width: $b3){
					position: static;
					margin: 0 auto .4em;
					transform: none;
				}
			}

			h4{
				margin-bottom: 0;
				padding-left: 67px;

				@media (max-width: $b3){
					width: 100%;
					padding-left: 0;
				}
			}
		}

		.btn-con{
			margin-top: 2em;

			@media (max-width: $b3){
				justify-content: center;
			}
		}
	}
}


.vertical-slide-with-text-section.vertical-slide-with-text-section{
	overflow: visible;

	@media (max-width: $b3){
		display: none;
	}

	.vertical-slide-con{

		@media (min-width: $b3 + 1){
			flex: 0 0 60%;
			max-width: 60%;
		}
	}

	.vertical-slide{
		height: 100vh;

		@media (min-width: $b3 + 1){
			min-height: 1080px;
		}

		// @media (min-width: $b3 + 1){
		// 	min-height: calc((226.7717px + 28.484px)* 4 + 74px);
		// }

		.swiper-wrapper-con{
			//overflow: visible!important;
		}

		.swiper-wrapper-con{
			height: 100vh;

			@media (min-width: $b3 + 1){
				min-height: 1080px;
			}

			// @media (min-width: $b3 + 1){
			// 	min-height: calc((226.7717px + 28.484px)* 4 + 74px);
			// }
		}

		.swiper-wrapper{
			flex-direction: column;
			pointer-events: none;
			transition-timing-function: linear;
		}

		.swiper-slide{
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 226.7717px;
			pointer-events: none;

			&.swiper-slide-active{
				pointer-events: auto;

				+ .swiper-slide{
					pointer-events: auto;
					
					.inner{
						opacity: 1;
						visibility: visible;
					}

					+ .swiper-slide{
						pointer-events: auto;
					
						.inner{
							opacity: 1;
							visibility: visible;
						}

						// + .swiper-slide{
						// 	pointer-events: auto;
					
						// 	.inner{
						// 		opacity: 0;
						// 		visibility: hidden;

						// 		+ .inner{
						// 			opacity: 1;
						// 			visibility: visible;
						// 		}
						// 	}
						// }
					}
				}

				.inner{
					opacity: 1;
					visibility: visible;
				}
			}

			.inner{
				width: calc(50% - 14px);
				background-color: white;
				border-radius: 9.1138px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				height: 226.7717px;
				transform: translateY(93px);
				opacity: 0;
				visibility: hidden;
				transition: $time;
				transform: translateY(74px);

				+ .inner{
					transform: translateY(0px);
				}
			}
		}
	}

	.row{
		padding-bottom: 80px;
	}

	.text{
		align-self: center;
		padding-top: 3.2em;

		@media (min-width: $b3 + 1){
			flex: 0 0 40%;
			max-width: 40%;
			padding-left: 49px;
		}
	}
}

.scroll-sections-section + .case-studies-section.case-studies-section{
	border-top: 1px solid rgba(white, .5);

	.row{
		padding-top: 117px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	.text-side{

		.text{
			width: 450px;

			@media (max-width: $b3){
				width: 100%;
			}
		}
	}
}

.case-studies-section.case-studies-section{
	overflow: visible;

	@media (max-width: $b3){
		text-align: center;
	}

	&.archive-page{

		&.hide-next-form + .sign-up-section{
			display: none;
		}

		.container {
			max-width: 1306px;
			position: relative;
	
			> .back-circle{
	
				&.c-o{
					width: 375.6115px;
					height: 375.6115px;
					bottom: auto;
					top: 178px;
					left: 99%;
					filter: blur(6px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}
			}

			.c-item:first-of-type{
				margin-top: 0;
			}
		}

		.row{
			padding-top: 203px;
			padding-bottom: 245px;

			@media (max-width: $b3){
				padding-top: 140px;
				padding-bottom: 80px;
			}
		}

		.text-side{
	
			.text{
				width: 440px;

				@media (max-width: $b3){
					width: 100%;
				}
			}

			h1{
				margin-bottom: .4em;
			}
		}
	}

	.container {
		max-width: 1332px;
	}
	
	.row{
		padding-top: 164px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	.text-side{
		// position: sticky;
		// top: 164px;

		.text{
			width: 420px;
			position: sticky;
			top: 124px;
			padding-bottom: 124px;

			@media (max-width: $b3){
				width: 100%;
				padding-bottom: 80px;

				.btn-con{
					justify-content: center;
				}
			}

			.filter-con{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 2.5em;

				@media (max-width: $b3){
					justify-content: center;
				}

				h6{
					margin-bottom: 10px;
					margin-right: 1.6em;
					color: white;

					@media (max-width: $b3){
						width: 100%;
						margin-right: 0;
					}
				}

				select{
					width: auto;
					margin-bottom: 10px;
					@include font-size(20);
					color: white;
					border-radius: 5.1429px;
					background-color: rgba($white, .22);
					background-color: #4a6aa7;
					border-color: rgba($white, 0);
					height: 66px;
					line-height: 64px;
					padding-left: 31px;
					background-image: url(../images/select-icon-alt.svg);

					@media (max-width: $b2){
						@include font-size(19);
					}
				
					@media (max-width: $b3){
						@include font-size(16);
					}

					option{
						color: $black;
					}
				}
			}
		}

		.circles{
			width: 700px;
			max-width: 700px;
			height: 700px;
			border-radius: 700px;
			position: absolute;
			top: 63.3%;
			left: -93.5%;
			transform: rotateZ(0deg);
			z-index: -1;

			@media (max-width: $b2){
				width: 600px;
				max-width: 600px;
				height: 600px;
				border-radius: 600px;
			}

			@media (max-width: $b4){
				display: none;
			}

			.back-circle{
				width: 201.7306px;
				height: 201.7306px;
				transform: translate(-50%,-50%);

				@media (max-width: $b3){
					width: 130px;
					height: 130px;
				}

				&:nth-of-type(1){
					top: 0;
					left: 50%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(2){
					top: 25%;
					left: 75%;
					top: 14.6%;
					left: 85.4%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(3){
					top: 50%;
					left: 100%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(4){
					top: 75%;
					left: 75%;
					top: 85.4%;
					left: 85.4%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(5){
					top: 100%;
					left: 50%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(6){
					top: 75%;
					left: 25%;
					top: 85.4%;
					left: 15%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(7){
					top: 50%;
					left: 0%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

				&:nth-of-type(8){
					top: 25%;
					left: 25%;
					top: 14.6%;
					left: 14.6%;
					filter: blur(5px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}

			}
		}
	}

	h2{
		margin-bottom: .45em;
	}

	.btn-con{
		margin-top: 1.9em;
	}

	.case-side{

		.c-item{
			margin-bottom: 4.25em;

			@media (max-width: $b3){
				text-align: left;
			}

			&:first-of-type{
				margin-top: 77.8vh;
				margin-top: 0;

				@media (max-width: $b3){
					margin-top: 0;
				}
			}

			&:last-of-type{
				margin-bottom: 18.1em;
				margin-bottom: 7em;

				@media (max-width: $b3){
					margin-bottom: 4.25em;
				}

				.inner{
					border-bottom: none;
				}
			}

			.inner{
				width: 463px;
				border-bottom: 1px solid white;
				position: relative;

				@media (max-width: $b3){
					width: 100%;
				}

				a{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;

					&:hover,
					&:focus-visible{
						
						~ h3{
							color: rgba($white, .5);
						}
					}
				}
			}

			h6{
				display: flex;
				align-items: center;
				margin-bottom: 1.65em;
				color: rgba(white, .5);

				svg{
					margin-right: 1.45em;
					width: 20.6924px;
				}
			}

			h3{
				@include line-height(41,43.5047);
				margin-bottom: 2.25em;
				transition: $time;
			}

		}
	}
}

.center-hero-section-con{
	overflow: hidden;

	.center-hero-section{
		overflow: visible;
	}
}

.center-hero-section{
	position: relative;

	.line{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 305px;
		width: 5px;
		overflow: hidden;

		@media (max-width: $b3){
			height: 200px;
		}

		&:before{
			content: '';
			position: absolute;
			top: 0;
			width: 1px;
			left: 50%;
			transform: translateX(-50%);
			background-color: white;
			height: 100%;
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			width: 5px;
			max-width: 5px;
			left: 50%;
			transform: translate(-50%, 0px);
			background-color: white;
			height: 94px;
			animation: scrollLC 2s 0s infinite ease-in;

			@media (max-width: $b3){
				animation: scrollLCM 2s 0s infinite ease-in;
			}

			@keyframes scrollLC{
				0%,
				10%,
				100%{
					transform: translate(-50%, 0px);
				}

				85%,
				99.9999%{
					transform: translate(-50%, 305px);
				}
			} 

			@keyframes scrollLCM{
				0%,
				10%,
				100%{
					transform: translate(-50%, 0px);
				}

				85%,
				99.9999%{
					transform: translate(-50%, 200px);
				}
			} 
		}
	}

	.container{
		position: relative;
		text-align: center;

		.back-circle{

			&.c-g{
				width: 244.3643px;
				height: 244.3643px;
				bottom: 57.4%;
				left: -17.85%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-y{
				width: 215.3057px;
				height: 215.3057px;
				bottom: 61%;
				left: 83.05%;
				filter: blur(10px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		
			&.c-r{
				width: 275.5064px;
				height: 275.5064px;
				bottom: 1.9%;
				left: 7.55%;
				filter: blur(4px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-o{
				width: 375.6115px;
				height: 375.6115px;
				bottom: -7.6%;
				left: 68.25%;
				filter: blur(4px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		
			&.c-b{
				width: 292.3381px;
				height: 292.3381px;
				bottom: 31.6%;
				left: 101.65%;
				filter: blur(5px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 257px;
		padding-bottom: 80px;

		@media (max-width: $b3){
			padding-top: 140px;
			padding-bottom: 80px;
		}
	}
}



.set-image-with-text-section + .intro-section {

	.row{
		padding-top: 155px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}
}

.intro-section{

	.container{
		text-align: center;
	}

	.row{
		padding-top: 62px;
		padding-bottom: 20px;
	}

	img{
		margin: 0 auto 1.7em;
		width: 50.0295px;
	}

	.text{
		width: 900px;
		margin: 0 auto;
	}
}

.scroll-sections-section{
	position: relative;

	.back-circle{

		&.c-g{
			width: 375.6115px;
			height: 375.6115px;
			bottom: 10%;
			left: 77.15%;
			filter: blur(6px);
			animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			z-index: 1;
		}
	}

	@media (max-width: $b3){
		text-align: center;
	}

	&.scroll-text.scroll-text.scroll-text{
		overflow: visible;

		.bottom-con{

			&.filler-con{
				height: 200px;

				@media (max-width: $b3){
					height: 100px;
				}
			}

			&.changing-backs{
				
				.row{
					padding-top: 0;
					padding-bottom: 120px;
					align-items: flex-start;
					
					@media (max-width: $b3){
						padding-bottom: 40px;
					}

					.link-side{
						position: sticky;
						top: 103px;

						@media (max-width: $b3){
							position: static;
						}
					}
				}
			}

			.text-inner{
				display: none;

				&.active{
					display: block;
				}
			}

			.title-menu h2 a.active{
				color: white;
				pointer-events: none;
			}
			
			// &:nth-of-type(1){

			// 	.link-side .title-menu h2:nth-of-type(1) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }

			// &:nth-of-type(2){

			// 	.link-side .title-menu h2:nth-of-type(2) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }

			// &:nth-of-type(3){

			// 	.link-side .title-menu h2:nth-of-type(3) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }

			// &:nth-of-type(4){

			// 	.link-side .title-menu h2:nth-of-type(4) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }

			// &:nth-of-type(5){

			// 	.link-side .title-menu h2:nth-of-type(5) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }

			// &:nth-of-type(6){

			// 	.link-side .title-menu h2:nth-of-type(6) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }

			// &:nth-of-type(7){

			// 	.link-side .title-menu h2:nth-of-type(7) a{
			// 		color: white;
			// 		pointer-events: none;
			// 	}
			// }
		}

		.bottom-con:last-of-type{
			
			.row{
				padding-bottom: 71px;
			}
		}

		.title-menu{

			@media (max-width: $b3){
				margin-bottom: 2em;
			}

			a{
				color: rgba(white, .5);

				&:hover,
				&:focus-visible{
					color: rgba(white, 1);
				}
			}
		}

		p{

			+ h3{
				margin-top: 1.6em;
				margin-bottom: .52em;
			}
		}

		ol{
			
			li{
				margin-bottom: 1em;
			}
		}

		ul {
			@include font-size(28);
			@include line-height(28,39.6);
			font-weight: 700;
			font-family: $alt-font;

			@media (max-width: $b2){
				@include font-size(23);
			}
		
			@media (max-width: $b3){
				@include font-size(20);
			}

			li{
				@include font-size(28);
				@include line-height(28,39.6);
				font-weight: 700;
				font-family: $alt-font;
				padding-left: 83px;
				border-bottom: 1px solid rgba(white, .5);
				padding-bottom: 1.1em;
				margin-bottom: 1.1em;

				@media (max-width: $b2){
					@include font-size(23);
				}
			
				@media (max-width: $b3){
					@include font-size(20);
				}

				&:before{
					left: 0.1em;
					top: 0.15em;
					width: 50.0295px;
					height: 50.0295px;
					border-radius: 50.0295px;
					background-color: transparent;
					background-size: 100% 100%;
					background-image: url(../images/a-logo.svg);
					background-repeat: no-repeat;
				}

				&:last-of-type{
					border-bottom: none;
				}
			}
		}

		.row{
			min-height: 0;
			padding-top: 144px;
			padding-bottom: 112px;

			@media (max-width: $b3){
				padding-top: 80px;
				padding-bottom: 80px;
			}
		}

		.text-side{
			padding-left: 33px;

			p{

				strong{
					font-weight: 600;
					letter-spacing: .02em;
				}
			}
		}
	}

	.container{
		position: relative;
	}

	.bottom-con-con{
		transition: $time;
		position: relative;

		.side-nav-con{
			position: absolute;
			left: 82px;
			height: 100%;
			width: 14px;

			@media (max-width: 1200px){
				left: 35px;
			}

			@media (max-width: 1024px){
				display: none;
			}
	
			@media (max-width: $b3){
				display: none;
			}
		}

		ol,
		ul{

			li{
				
				@media (max-width: $b3){
					text-align: left;
				}
			}
		}

		.side-nav{
			position: absolute;
			top: calc(50vh - (128px/2));
			z-index: 2;

			&.bottom{
				top: auto;
				bottom: calc(50vh - (128px/2));
			}
			
			&.fixed{
				position: fixed;
				top: 50%;
				transform: translateY(-50%);
			}
			
			&:after{
				content: '';
				position: absolute;
				top: 24px;
				bottom: 24px;
				left: 50%;
				width: 1px;
				background-color: white;
				transform: translateX(-50%);
				z-index: -1;
			}
			
			a{
				display: block;
				width: 20px;
				height: 20px;
				margin: 24px auto;
				position: relative;
				background-color: white;
				width: 14px;
				height: 14px;
				border-radius: 14px;

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 6px;
					height: 6px;
					border-radius: 3px;
					background-color: black;
					transition: .2s;
					opacity: 0;
				}

				&:hover{

					&:after{
						background-color: white;
					}
				}

				&.active{

					&:after{
						opacity: 1;
					}
				}
			}
		}
	}

	.bottom-con{
		transition: $time;
		opacity: 0;
		visibility: hidden;

		$particle-count: 60;
		$particle-size: 1rem;
		$particle-distance: 100vh;
		$particle-min-speed: 5000;  // ms
		$particle-max-speed: 15000; // ms
		$particle-max-delay: 12000; // ms

		&.active{
			opacity: 1;
			visibility: visible;

			.c-g-s{

				.bottom-particles{

					.bubble{
						
						// Randomize bubles
						@for $i from 1 through $particle-count{
						
							// random animation speed
							$speed: random($particle-max-speed) + 0ms;
							@if $speed < $particle-min-speed{
								$speed: $particle-min-speed + 0ms;
							}
								
							&:nth-child(#{$i}){
								left: random(100) + 0%;
								animation: blow $speed infinite;
								animation-delay: random($particle-max-delay) + 0ms;
							}
						}
					}
				}

				@keyframes blow {
					0% {
						opacity: 0;
						transform:translate(0, 0);
					}
					20% { 
						opacity: 1;
					}
					100% {
						opacity: 0;
						transform:translate(0, -$particle-distance) scale(.2);
					}
				}
			}

			.c-o-s{
	
				svg{
	
					circle{
						animation-name: preloader;
						animation-duration: 3s;
						animation-iteration-count: infinite;
						animation-timing-function: ease-in-out;
					}
					
					@keyframes preloader {
						50% {
							transform: rotate(420deg);
						}
					}
				}
			}

			.c-y-s{
				
			}
		}
		
		.container.bottom{
			position: static;
			top: 0;
		}

		.circle-side{
			padding-right: 36px;
			padding-left: 10px;

			@media (max-width: $b3){
				padding-right: 26px;
				padding-left: 26px;
			}
		}

		.side-c{
			width: 607px;
			height: 607px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 50% 50%;
			z-index: 1;

			@media (max-width: 1310px){
				width: 400px;
				height: 400px;
			}

			@media (max-width: 950px){
				width: 335px;
				height: 335px;
			}

			@media (max-width: $b3){
				margin: 2em auto;
				width: 400px;
				height: 400px;
			}

			@media (max-width: $b4){
				width: 268px;
				height: 268px;
			}

			&.c-g-s{
				background-image: url(../images/c-g-s.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				position: relative;

				.bottom-particles{
					position: absolute;
					bottom: 6.4%;
					left: 6.4%;
					width: 87.2%;
					height: 87.2%;
					overflow: hidden;
					border-radius: 50%;
					clip-path: circle(50% at 50% 50%);

					.bubble{
						opacity:0;
						position: absolute;
						bottom: -$particle-size;
						width: $particle-size;
						height: $particle-size;
						background-color:rgba($white ,0.5);
						border-radius:50%;
					}
				}
			}

			&.c-o-s{
				background-image: url(../images/c-o-s.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				position: relative;

				svg{
					position: absolute;
					bottom: 6.4%;
					left: 6.4%;
					width: 87.2%;
					height: 87.2%;
					overflow: hidden;
					border-radius: 50%;
					clip-path: circle(50% at 50% 50%);

					circle{
						fill: none;
						stroke-width: 3;
						transform-origin: 303px 303px;
						will-change: transform;
					
						&:nth-of-type(1) {
							stroke-dasharray: 1180px;
						}
					
						&:nth-of-type(2) {
							stroke-dasharray: 550px;
						}
					
						&:nth-of-type(3) {
							stroke-dasharray: 720px;
						}
					
						&:nth-of-type(4) {
							stroke-dasharray: 380px;
						}

						&:nth-of-type(5) {
							stroke-dasharray: 190px;
						}
					
						@for $i from 1 through 5 {

							&:nth-of-type(#{$i}) {
								animation-delay: -#{$i * 0.15}s;
							}
						}
					}
				}
			}

			&.c-y-s{
				background-image: url(../images/c-y-s.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				position: relative;

				canvas{
					display: block;
					vertical-align: bottom;
				}
				  
				  
				#particles-js{
					position: absolute;
					bottom: 6.4%;
					left: 6.4%;
					width: 87.2%;
					height: 87.2%;
					overflow: hidden;
					border-radius: 50%;
				}
			}
		}
	}

	.container.bottom .row{
		min-height: 100vh;
		padding-top: 160px;
		padding-bottom: 188px;
		align-items: center;

		@media (max-width: 1024px){
			padding-top: 80px;
			padding-bottom: 80px;
			min-height: 0;
		}

		@media (max-width: $b3){
			padding-top: 80px;
			padding-bottom: 80px;
			min-height: 0;
		}
	}

	.text-side{
		padding-left: 142px;

		@media (max-width: 1200px){
			padding-left: 80px;
		}

		@media (max-width: $b3){
			padding-left: 26px;
			order: 2;
		}
	}

	.btn{
		margin-top: 1em;
	}
}

.team-section{
	text-align: center;

	@media (min-width: $b3 + 1){
		
		#mouse{
			z-index: 4;
			pointer-events: none;
			background-image: url(../images/hover.svg);
			background-repeat: no-repeat;
			width: 118.1959px;
			height: 118.1959px;
			border-radius: 118.1959px;
			position: absolute;
			transform: translate(-50%, -50%);

			&.hide{
				display: none;
			}
		}
	}

	@media (max-width: $b3){
		
		#mouse{
			display: none;
		}
	}

	.container{
		max-width: 1296px;
		position: relative;
		text-align: center;

		@media (max-width: 1374px){
			
			@media (min-width: $b3 + 1){
				max-width: calc(100% - 80px);
			}
		}

		.back-circle{

			&.c-g{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 45.4%;
				left: 92%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	#hiddenHex {
		overflow: hidden;
		height: 0;
		width: 0;
	}

	.row{
		padding-top: 200px;
		padding-bottom: 25px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	.text{
		width: 750px;
		margin: 0 auto 1.86em;
	}

	h2{
		margin-bottom: .45em;
	}

	.btn-con{
		margin-top: 2.25em;
	}

	.team-slide{
		
		// @media (min-width: $b3 + 1){
		// 	cursor: none;

		// 	*{
		// 		cursor: none;
		// 	}
		// }

		@media (max-width: $b3){
		
			.swiper-wrapper-con{
				overflow: visible!important;
			}
		}

		@media (max-width: $b3){
			margin-bottom: 70px;
		}

		.img-con{
			clip-path: url(#Hex);
			//height: 329px;
			width: 100%;
			margin-bottom: 1.4em;
			aspect-ratio: 1 / 1.131;
			background-size: 100% auto;
			transition: $time;
		}

		.inner{
			position: relative;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				&:hover,
				&:focus-visible{

					~ .img-con{
						background-size: 110% auto;
					}
					
					~ h4,
					~ .role{
						opacity: .5;
					}
					
				}
			}
		}

		h4{
			margin-bottom: 0;
		}

		h4,
		.role{
			opacity: 1;
			transition: $time;
		}

		.swiper-button-next,
		.swiper-button-prev{
			top: 164px;
			top: 38.6%;

			@media (max-width: $b3){
				top: calc(100% + 15px);
			}
		}
	}
}

.team-pop{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba($grey-dark, .8);
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	transition: $time;
	display: flex;
	justify-content: center;
	align-items: center;

	&.open{
		opacity: 1;
		visibility: visible;
	}
	
	.pop-inner{
		width: 1240px;
		max-width: calc(100% - 82px);
		background-color: white;
		position: relative;

		@media (max-width: $b3){
			max-width: calc(100% - 52px);
		}

		.close{
			width: 46px;
			height: 46px;
			border-radius: 46px;
			position: absolute;
			top: 33px;
			right: 36px;
			background-image: url(../images/close.svg);
			background-repeat: no-repeat;
			opacity: 1;
			transition: $time;
			cursor: pointer;
			z-index: 1;

			@media (max-width: $b3){
				top: 12px;
				right: 12px;
			}

			&:hover,
			&:focus-visible{
				opacity: .5;
			}
		}

		.row{
			max-height: calc(100vh - 82px);

			@media (min-width: $b3 + 1){
				overflow: auto;
			}

			@media (max-width: $b3){
				max-height: calc(100vh - 52px);
			}
		}

		.col-md-4{
			padding: 0;
			align-self: stretch;
			max-height: calc(100vh - 82px);

			@media (max-width: $b3){
				display: none;
			}

			.img-con{
				width: 100%;
				padding-top: 123%;
				background-color: red;
				height: 100%;
				background-position: 50% 0%;
			}
		}

		.col-md-8{
			text-align: center;
			align-self: center;
			padding-top: 80px;
			padding-bottom: 43px;

			.inner{
				overflow: auto;
				max-height: calc(100vh - 52px - 80px - 43px);
			}
			

			@media (max-width: $b3){
				padding-top: 55px;
				padding-bottom: 26px;
				
				.inner{
					overflow: auto;
					max-height: calc(100vh - 52px - 55px - 26px);
				}
			}

			*{
				color: $blue-dark;
			}

			h3{
				margin-bottom: .15em;
			}

			h4{
				margin-bottom: 1.15em;
			}

			.bio{
				margin: 0 auto;
				width: 600px;

				p,
				li{
					@include font-size(21);
					@include line-height(21,31.5047);

					@media (max-width: $b2){
						@include font-size(19);
					}
				
					@media (max-width: $b3){
						@include font-size(16);
					}
				}
			}
		}
	}
}

.partners-section{
	text-align: center;

	.container{
		position: relative;
		max-width: 1180px;

		@media (max-width: 1374px){
			
			@media (min-width: $b3 + 1){
				max-width: calc(100% - 110px);
			}
		}

		.back-circle{

			&.c-g{
				width: 157.958px;
				height: 157.958px;
				bottom: 54%;
				left: 1.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-r{
				width: 244.3643px;
				height: 244.3643px;
				bottom: 65.5%;
				left: -17.45%;
				filter: blur(4px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 187px;
		padding-bottom: 25px;

		@media (max-width: $b3){
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	.text{
		width: 750px;
		margin: 0 auto 3.8em;
	}

	h2{
		margin-bottom: .45em;
	}

	.partner-slide{

		@media (max-width: $b3){
			
			.swiper-wrapper-con{
				overflow: visible!important;
			}
		}

		.inner{
			width: 100%;
			background-color: white;
			border-radius: 9.1138px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			min-height: 226.7717px;

			@media (max-width: $b3){
				min-height: 170px;
			}
		}
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 28px;
			padding-left: 28px;
		}

		.row{
			margin-right: -28px;
			margin-left: -28px;
		}
	}
}

.column-text-section{

	.container{
		max-width: 1296px;
		position: relative;

		.back-circle{

			&.c-y{
				width: 248.629px;
				height: 248.629px;
				bottom: 35%;
				left: 93.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 136px;
		padding-bottom: 25px;
	}

	.title{
		position: relative;
		margin-bottom: .8em;

		img{
			position: absolute;
			width: 61.0343px;
			top: 0em;
			left: 41px;

			@media (max-width: $b2){
				top: 32%;
				transform: translateY(-50%);
				width: 50px;
			}
		}

		h2{
			padding-left: 88px;
		}
	}

	.left{

		@media (min-width: $b3 + 1){
			flex: 0 0 44.1%;
			max-width: 44.1%;
			padding-right: 50px;
		}
	}

	.right{

		@media (min-width: $b3 + 1){
			flex: 0 0 55.9%;
			max-width: 55.9%;
		}
	}
}

.case-hero-section + .image-with-text-section{

	.row{
		padding-top: 87px;

		.inner{
			margin-top: .5em;
		}

		h3{
			@include line-height(41,43.5047);
			margin-bottom: .8em;
		}
	}
}

.image-with-text-section{

	&.right{

		.image-side{
			order: 2;

			.img-con{
				float: none;
			}
		}
	}

	.container{
		position: relative;

		.back-circle{

			&.c-o{
				width: 309.7563px;
				height: 309.7563px;
				bottom: 51%;
				left: 96.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}
	
	.row{
		padding-top: 119px;
		padding-bottom: 119px;
		align-items: center;

		@media (max-width: $b3){
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	.image-side{

		.img-con{
			min-height: 494.1842px;
			width: calc(100% + ((100vw - #{$base-width})/2) + 5px);
			max-width: calc(100% + ((100vw - #{$base-width})/2) + 5px);
			float: right;
			margin-right: -5px;

			&.container-bg{
				background-size: contain;
				width: 100%;
			}

			@media (max-width: 1306px){
				width: calc(100% + 41px);
				max-width: calc(100% + 41px);
				margin-right: 0px;
			}

			@media (max-width: $b3){
				float: none;
				width: calc(100% + 26px + 26px);
				max-width: calc(100% + 26px + 26px);
				margin-left: -26px;
				min-height: 0;
				padding-top: 60%;
			}
		}
	}

	.btn-con{
		margin-top: 2em;
	}

	.inner{
		margin-top: 2.2em;
	}
}

.case-studies-section + .sign-up-section{

	.back-circle{
		display: none;
	}
}

.scroll-sections-section.scroll-text + .sign-up-section{

	.back-circle.c-r{
		background-image: url(../images/c-g.svg);
	}

	.row{
		padding-top: 145px;
		padding-bottom: 99px;
	}
}

.news-section.single + .sign-up-section{
	
	.back-circle.c-r.c-r{
		bottom: -20%;
	}

	.row{
		padding-bottom: 112px;
	}
}

.news-content-section + .sign-up-section,
.join-content-section + .sign-up-section{

	.back-circle.c-r.c-r{
		bottom: -10%;
	}

	.row{
		padding-top: 170px;
		padding-bottom: 112px;

		@media (max-width: $b3){
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}
}

.join-content-section + .sign-up-section{
	background: linear-gradient(to bottom, rgba(0,83,161,1) 0%, rgba(49,39,130,1) 33%, rgba(49,39,130,1) 100%);
}

.sign-up-section{

	.search &{
		display: none;
	}

	.post-type-archive-case-studies &{
		
		.row{
			padding-bottom: 99px;
		}
	}

	.container{
		position: relative;

		.back-circle{

			&.c-r{
				width: 375.6115px;
				height: 375.6115px;
				bottom: -34%;
				left: -18%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 25px;
		padding-bottom: 59px;
	}

	h2{
		@include line-height(67,71.5047);

		span{
			color: $blue;
		}
	}

	.form-side{
		
		@media (min-width: $b3 + 1){
			margin-top: .4em;
		}

		::-webkit-input-placeholder {
			@include font-size(20);
			color: white;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
			}
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			@include font-size(20);
			color: white;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
			}
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(20);
			color: white;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
			}
		}
		
		:-ms-input-placeholder {
			@include font-size(20);
			color: white;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
			}
		}

		input,
		input.input-text,
		textarea{
			@include font-size(20);
			color: white;
			border-radius: 5.1429px;
			padding: 19px 32px;
			background-color: rgba($white, .22);
			border-color: rgba($white, 0);
			margin-bottom: 26px;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
			}
		}

		input[type="radio"],
		input[type="checkbox"]{

			& + span,
			& + .wpcf7-list-item-label,
			& + label{
				padding-left: 64px!important;
				@include font-size(17);
				line-height: 23.5047px;
				margin-bottom: 5px;
				letter-spacing: .01em;
				color: white;

				@media (max-width: $b2){
					@include font-size(16);
				}
			
				@media (max-width: $b3){
					@include font-size(13);
					padding-left: 34px!important;
				}

				a{
					color: $blue;

					&:hover,
					&:focus-visible{
						color: $blue-dark;
					}
				}

				&:before{
					width: 43.8777px;
					height: 43.8777px;
					left: 0px;
					top: 0px;
					border-radius: 43.8777px;
					background-color: rgba($white, .22);

					@media (max-width: $b3){
						width: 22px;
						height: 22px;
					}

				}

				&:after{
					position: absolute;
					content: "";
					width: 23.8777px;
					height: 23.8777px;
					left: 10px;
					top: 10px;
					border-radius: 43.8777px;
					background-color: $blue-dark;
					opacity: 0;
					transition: $time;

					@media (max-width: $b3){
						width: 16px;
						height: 16px;
						left: 3px;
						top: 3px;
					}
				}
			}

			&:checked + label:after,
			&:checked + .wpcf7-list-item-label:after,
			&:checked + span:after,
			& + label.checked:after{
				opacity: 1;
			}
		}

		input[type="radio"]{

			& + span,
			& + .wpcf7-list-item-label,
			& + label{

				&:before,
				&:after{
					border-radius: 43.8777px;
				}
			}
		}

		.wpcf7 div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output, 
		.wpcf7 div.wpcf7-mail-sent-ok, 
		.wpcf7 div.wpcf7-validation-errors, .wpcf7 div.wpcf7-acceptance-missing{
			border-radius: 5.1429px;
		}

		.wpcf7-list-item{
			margin-top: .65em;
		}

		.medium{

			@media (min-width: $b3 + 1){
				width: calc(56% - 5px);
			}
			
			+ .medium{

				@media (min-width: $b3 + 1){
					width: calc(44% - 5px);
					display: flex;
					justify-content: flex-end;
					align-items: flex-start;
				}
			}
		}

		.btn{
			margin-top: 2px;
		}
	}
}

.contact-section{

	.row{
		padding-top: 228px;
		padding-bottom: 114px;

		@media (max-width: $b3){
			padding-top: 150px;
			padding-bottom: 80px;
		}
	}

	.container{
		max-width: 1302px;
		position: relative;

		.back-circle{

			&.c-r{
				width: 257.3342px;
				height: 257.3342px;
				bottom: 58%;
				left: 91.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-o{
				width: 375.6115px;
				height: 375.6115px;
				bottom: -3%;
				left: 38.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.contact-side{
		padding-top: 4px;

		@media (min-width: $b2 + 1){
			flex: 0 0 53.5%;
			max-width: 53.5%;
		}

		@media (max-width: $b2){
			flex: 0 0 100%;
			max-width: 100%;
		}

		h6{
			margin-bottom: .4em;
		}

		address,
		div{
			color: white;
			font-weight: 700;
			@include font-size(28);
			@include line-height(28,37.5047);
			letter-spacing: .01em;
			font-family: $alt-font;

			@media (max-width: $b2){
				@include font-size(23);
			}
		
			@media (max-width: $b3){
				@include font-size(20);
			}

			a{
				color: white;

				&:hover,
				&:focus-visible{
					color: $blue;
				}
			}
		}

		.address{
			margin-top: 1.3em;
		}

		address{
			@include font-size(22);
			@include line-height(22,31.5047);

			@media (max-width: $b2){
				@include font-size(19);
			}
		
			@media (max-width: $b3){
				@include font-size(16);
			}
		}

		.social-menu{
			margin-top: 2em;

			a{
				@include font-size(28);
				border-width: 2px;
				line-height: 48px;
				height: 52px;
				min-width: 48px;
				margin: 0 22px 22px 0;

				@media (max-width: $b2){
					@include font-size(23);
				}
			
				@media (max-width: $b3){
					@include font-size(20);
				}

				i{
					@include font-size(28);
					line-height: 48px;
					min-width: 48px;

					@media (max-width: $b2){
						@include font-size(23);
					}
				
					@media (max-width: $b3){
						@include font-size(20);
					}
				}
			}
		}
	}

	.form-side{

		@media (min-width: $b2 + 1){
			flex: 0 0 46.5%;
			max-width: 46.5%;
		}

		@media (max-width: $b2){
			flex: 0 0 100%;
			max-width: 100%;
		}

		.inner{
			background-color: white;
			border-radius: 28.2196px;
			padding: 52px 49px;
	
			@media (max-width: $b3){
				border-radius: 26px;
				padding: 52px 26px;
			}	
		}
		
		*[type="submit"], 
		.submit-con [type="submit"]{
			margin-top: 2px;
		}

		.submit-con{

			+ .large{
				margin-top: 33px;
			}
		}
	}
}

.hero-sections-section{

	&:after{
		top: auto;
		bottom: 0;
		transform: none;
		background-position: 0 100%;
	}

	.container{
		position: relative;

		.back-circle{

			&.c-b{
				width: 292.3381px;
				height: 292.3381px;
				bottom: 5.8%;
				left: 99.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-y{
				width: 215.3057px;
				height: 215.3057px;
				bottom: 5.8%;
				left: 103%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-o{
				width: 292.3381px;
				height: 292.3381px;
				bottom: 5.8%;
				left: 99.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 184px;
		padding-bottom: 55px;
		align-items: center;

		.scroll{
			text-transform: uppercase;
			@include font-size(19);
			position: absolute;
			bottom: 42px;
			left: 41px;
			font-weight: 700;
			font-family: $alt-font;
			letter-spacing: .09em;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
				display: none;
			}
		}
	}

	$particle-count: 60;
	$particle-size: 1rem;
	$particle-distance: 100vh;
	$particle-min-speed: 5000;  // ms
	$particle-max-speed: 15000; // ms
	$particle-max-delay: 12000; // ms

	.c-g-s{

		.bottom-particles{

			.bubble{
				
				// Randomize bubles
				@for $i from 1 through $particle-count{
				
					// random animation speed
					$speed: random($particle-max-speed) + 0ms;
					@if $speed < $particle-min-speed{
						$speed: $particle-min-speed + 0ms;
					}
						
					&:nth-child(#{$i}){
						left: random(100) + 0%;
						animation: blow $speed infinite;
						animation-delay: random($particle-max-delay) + 0ms;
					}
				}
			}
		}

		@keyframes blow {
			0% {
				opacity: 0;
				transform:translate(0, 0);
			}
			20% { 
				opacity: 1;
			}
			100% {
				opacity: 0;
				transform:translate(0, -$particle-distance) scale(.2);
			}
		}
	}

	.c-o-s{

		svg{

			circle{
				animation-name: preloader;
				animation-duration: 3s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
			}
			
			@keyframes preloader {
				50% {
					transform: rotate(420deg);
				}
			}
		}
	}

	.container.bottom{
		position: static;
		top: 0;
	}

	.circle-side{
		padding-right: 61px;
		padding-left: 19px;
		
		@media (max-width: $b3){
			padding-right: 24px;
			padding-left: 24px;
		}
	}

	.side-c{
		width: 573px;
		height: 573px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: 50% 50%;

		@media (max-width: 1310px){
			width: 400px;
			height: 400px;
		}

		@media (max-width: 950px){
			width: 335px;
			height: 335px;
		}

		@media (max-width: $b3){
			margin: 0 auto 2em;
			width: 400px;
			height: 400px;
		}

		@media (max-width: $b4){
			width: 268px;
			height: 268px;
		}

		&.c-g-s{
			background-image: url(../images/c-g-s.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			position: relative;

			.bottom-particles{
				position: absolute;
				bottom: 6.4%;
				left: 6.4%;
				width: 87.2%;
				height: 87.2%;
				overflow: hidden;
				border-radius: 50%;
				clip-path: circle(50% at 50% 50%);

				.bubble{
					opacity:0;
					position: absolute;
					bottom: -$particle-size;
					width: $particle-size;
					height: $particle-size;
					background-color:rgba($white ,0.5);
					border-radius:50%;
				}
			}
		}

		&.c-o-s{
			background-image: url(../images/c-o-s.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			position: relative;

			svg{
				position: absolute;
				bottom: 6.4%;
				left: 6.4%;
				width: 87.2%;
				height: 87.2%;
				overflow: hidden;
				border-radius: 50%;
				clip-path: circle(50% at 50% 50%);

				circle{
					fill: none;
					stroke-width: 3;
					transform-origin: 303px 303px;
					will-change: transform;
				
					&:nth-of-type(1) {
						stroke-dasharray: 1180px;
					}
				
					&:nth-of-type(2) {
						stroke-dasharray: 550px;
					}
				
					&:nth-of-type(3) {
						stroke-dasharray: 720px;
					}
				
					&:nth-of-type(4) {
						stroke-dasharray: 380px;
					}

					&:nth-of-type(5) {
						stroke-dasharray: 190px;
					}
				
					@for $i from 1 through 5 {

						&:nth-of-type(#{$i}) {
							animation-delay: -#{$i * 0.15}s;
						}
					}
				}
			}
		}

		&.c-y-s{
			background-image: url(../images/c-y-s.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			position: relative;

			canvas{
				display: block;
				vertical-align: bottom;
			}
				
				
			#particles-js{
				position: absolute;
				bottom: 6.4%;
				left: 6.4%;
				width: 87.2%;
				height: 87.2%;
				overflow: hidden;
				border-radius: 50%;
				clip-path: circle(50% at 50% 50%);
			}
		}
	}

	.text-side{
		padding-bottom: 2.6em;

		h1{
			margin-bottom: .46em;
		}

		h3{
			@include line-height(41,51.5047);
		}
	}
	
}

.stats-alt-section,
.stats-section{
	
	&:after{
		background-position: 0 100%;
	}
	
	.row{
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.container{
		max-width: 1116px;
		position: relative;

		.back-circle{

			&.c-g{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 2.8%;
				left: -42%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.logo-top{
		position: relative;
		
		img{
			margin: 0 auto .78em;
		}

		.line{
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			width: 5px;
			height: 210px;

			@media (max-width: $b3){
				display: none;
			}

			@media (max-width: $b3){
				height: 200px;
			}
	
			&:before{
				content: '';
				position: absolute;
				top: 0;
				width: 1px;
				left: 50%;
				transform: translateX(-50%);
				background-color: white;
				height: 100%;
			}
	
			&:after{
				content: '';
				position: absolute;
				top: 0;
				width: 5px;
				max-width: 5px;
				left: 50%;
				transform: translate(-50%, 0px);
				background-color: white;
				height: 94px;
				animation: scrollL 2s 0s infinite ease-in;

				@media (max-width: $b3){
					animation: scrollLM 2s 0s infinite ease-in;
				}
			}
		}
	}

	.col-md-6{
		padding-top: 83px;
		padding-bottom: 100px;
		text-align: center;

		@media (max-width: $b3){
			padding-top: 40px;
			padding-bottom: 40px;
		}

		h2{
			margin-bottom: .41em;
		}
	}
}

.service-hero-section + .stats-alt-section{

	.container{
		max-width: 1376px;

		.back-circle{

			&.c-r{
				display: none;
			}
		}
	}
}

.stats-alt-section{
	overflow: visible!important;

	.row{
		padding-top: 44px;
		padding-bottom: 44px;
		justify-content: center;
	}

	.container{
		max-width: 1456px;

		.back-circle{

			&.c-r{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 28.8%;
				left: -23%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.col-md-6{
		padding-top: 31px;
		padding-bottom: 14px;
		

		+ .col-md-6{
			border-left: 1px solid white;

			@media (max-width: $b2){
				border-left: none;
			}
		}

		@media (max-width: $b2){

			&:last-of-type:nth-of-type(odd){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.inner{
			margin: 0 auto;
			width: 340px;
		}
	}
}

.main-wrapper{

	section:last-of-type().partners-section,
	section:last-of-type().set-image-with-text-section{
		
		.row{
			padding-bottom: 110px;

			@media (max-width: $b3){
				padding-top: 80px;
			}
		}
	}
}

.set-image-with-text-section{

	&.right{

		@media (min-width: $b3 + 1){
			
			.image-side{
				order: 2;
			}
		}
	}

	&.none{

		@media (min-width: $b3 + 1){
			
			.row{
				padding-top: 196px;
				padding-bottom: 35px;
			}
		}
	}

	.container{
		position: relative;

		.back-circle{

			&.c-o{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 8%;
				left: 100.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-r{
				width: 153.0812px;
				height: 153.0812px;
				bottom: 50%;
				left: -8%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;

				@media (max-width: $b3){
					display: none;
				}
			}

			&.c-g{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 6%;
				left: 9%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;

				@media (max-width: $b3){
					display: none;
				}
			}
		}
	}
	
	.row{
		padding-top: 110px;
		padding-bottom: 35px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	h3{
		@include line-height(41,51.5047);
		margin-bottom: .7em;
	}

	h4{
		@include line-height(28,37.5047);
		margin-bottom: 1.3em;
	}

	.text-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 50.8%;
			max-width: 50.8%;
		}

		@media (max-width: $b3){
			margin-top: 30px;
		}
	}

	.image-side{
		padding-top: .7em;

		@media (min-width: $b3 + 1){
			flex: 0 0 49.2%;
			max-width: 49.2%;
		}

		&.animate{

			.graph{

				g{

					path:nth-of-type(7){
						stroke-dashoffset: 0px;
						transition: .5s .2s;
					}
				}

				circle{
					opacity: 1;
					transition: .25s .7s;

					&:nth-of-type(6){
						transition: .25s .7s;
					}

					&:nth-of-type(5){
						transition: .25s .9s;
					}

					&:nth-of-type(4){
						transition: .25s 1.1s;
					}

					&:nth-of-type(3){
						transition: .25s 1.2s;
					}

					&:nth-of-type(2){
						transition: .25s 1.4s;
					}

					&:nth-of-type(1){
						transition: .25s 1.6s;
					}

				}
			}

			.square{

				circle{

					&:nth-of-type(4){
						//transition: .8s .2s;
						//transform: translateX(69%);
						animation: square1 3.2s .2s linear infinite;
					}

					&:nth-of-type(3){
						//transition: .8s 1.0s;
						//transform: translateY(69%);
						animation: square2 3.2s 1.0s linear infinite;
					}

					&:nth-of-type(2){
						//transition: .8s 1.8s;
						//transform: translateX(-69%);
						animation: square3 3.2s 1.8s linear infinite;
					}

					&:nth-of-type(1){
						//transition: .8s 2.6s;
						//transform: translateY(-69%);
						animation: square4 3.2s 2.6s linear infinite;
					}

					@keyframes square1 {
						0%,
						100%{
							transform: translateX(0%);	
						}

						25%,
						99.999%{
							transform: translateX(69%);
						}
					}

					@keyframes square2 {
						0%,
						100%{
							transform: translateY(0%);
						}

						25%,
						99.999%{
							transform: translateY(69%);
						}
					}

					@keyframes square3 {
						0%,
						100%{
							transform: translateX(-0%);
						}

						25%,
						99.999%{
							transform: translateX(-69%);
						}
					}

					@keyframes square4 {
						0%,
						100%{
							transform: translateY(-0%);
						}

						25%,
						99.999%{
							transform: translateY(-69%);
						}
					}
				}
			}

			.bar{

				circle{
					opacity: 1;
					transition: .25s .7s;

					&:nth-of-type(3){
						transition: .25s .7s;
					}

					&:nth-of-type(2){
						transition: .25s .9s;
					}

					&:nth-of-type(1){
						transition: .25s 1.1s;
					}

					&:nth-of-type(4){
						transition: .25s 1.3s;
					}
				}
			}

			.rings{

				g{
					transform-origin: center;

					&:nth-of-type(2){
						animation: rings 5s 0s linear infinite;
					}

					&:nth-of-type(3){
						animation: rings 6s 0s linear infinite reverse;
					}

					&:nth-of-type(1){
						animation: rings 7s 0s linear infinite;
					}
				}

				@keyframes rings{
					0%,
					100%{
						transform: rotate(0deg);
					}

					99.999%{
						transform: rotate(359.999deg);
					}
				}
			}

			.side{

				circle{
					opacity: 1;
					transition: .25s .7s;

					&:nth-of-type(1){
						transition: .25s .7s;
					}

					&:nth-of-type(4){
						transition: .25s .9s;
					}

					&:nth-of-type(2){
						transition: .25s 1.1s;
					}

					&:nth-of-type(3){
						transition: .25s 1.3s;
					}
				}
			}

			.hex{

				circle{
					opacity: 1;
					transition: .25s .7s;

					&:nth-of-type(1){
						transition: .25s .7s;
					}

					&:nth-of-type(6){
						transition: .25s .9s;
					}

					&:nth-of-type(4){
						transition: .25s 1.1s;
					}

					&:nth-of-type(3){
						transition: .25s 1.3s;
					}

					&:nth-of-type(5){
						transition: .25s 1.5s;
					}

					&:nth-of-type(2){
						transition: .25s 1.7s;
					}
				}
			}
		}

		.graph{

			@media (max-width: $b3){
				
				svg{
					margin: 0 auto;
				}
			}

			g{

				path:nth-of-type(7){
					stroke-dashoffset: 905px;
					stroke-dasharray: 905px;
				}
			}

			circle{
				opacity: 0;
			}
		}

		.square{

			@media (min-width: $b3 + 1){
				margin-top: -1.6em;
			}
			
			svg{
				margin: 0 auto;
			}
		}

		.bar{

			svg{
				margin-top: -2.2em;
				margin-bottom: 3.4em;

				@media (max-width: $b3){
					margin-right: auto;
					margin-left: auto;
				}
			}

			circle{
				opacity: 0;
			}
		}

		.rings{

			@media (min-width: $b3 + 1){
				width: 102.65%;
				max-width: 102.65%;
			}

			svg{
				margin: 0 auto;
				margin-top: -4.2em;
				margin-bottom: -1em;
			}
		}

		.side{

			svg{
				margin-top: -1.9em;
				// margin-bottom: -1em;

				@media (max-width: $b3){
					margin-right: auto;
					margin-left: auto;
				}
			}

			circle{
				opacity: 0;
			}
		}

		.hex{

			svg{
				margin: 0 auto;
				margin-top: 2em;
				// margin-bottom: -1em;

				@media (max-width: $b3){
					margin-top: 0;
				}
			}

			circle{
				opacity: 0;
			}
		}
	}

	.btn-con{
		margin-top: 2em;
	}

	dl.accordion{
		margin-bottom: -1.5em;
		width: 560px;

		dt{
			@include font-size(28);
			font-family: $alt-font;
			padding: 10.5px 0px 10.5px 66px;
			cursor: pointer;
			background-image: url(../images/plus.svg);
			background-repeat: no-repeat;
			background-position: 2px 0.58em;

			@media (max-width: $b2){
				@include font-size(23);
			}
		
			@media (max-width: $b3){
				@include font-size(20);
				padding: 10.5px 0px 10.5px 44px;
			}

			&.no-text{
				pointer-events: none;
			}
			
			&.open{
				background-image: url(../images/minus.svg);

				+ dd{
					transition: $time;
				}
			}
		}

		dd{
			max-height: 0;
			overflow: hidden;
			border-bottom: 1px solid rgba(white, .5);
			margin: 0;
			transition: $time;

			&:last-of-type{
				border-bottom: none;
			}

			p:last-child{
				margin-bottom: 0;
			}

			.inner{
				padding: 0px 20px 37px 66px;
				
				@media (max-width: $b3){
					padding: 0px 20px 26px 44px;
				}
			}

			.btn-con{
				margin-top: -.3em;

				.btn{
					padding: 9.8px 30px;
					font-size: 1.7rem !important;

					svg{
						margin: 12px -3px 12px 12px;
					}
				}
			}
		}
	}
}

.other-data-section{

	.container{
		position: relative;

		.back-circle{

			&.c-b{
				width: 292.3381px;
				height: 292.3381px;
				top: 8%;
				bottom: auto;
				left: 94.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	&:after{
		left: auto;
		right: -17.8%;
	}

	@media (min-width: $b3 + 1){

		.container{
			max-width: 1236px;
		}
		
		.container,
		.col-12{
			padding-right: 10px;
			padding-left: 10px;
		}

		.row{
			margin-right: -10px;
			margin-left: -10px;
		}
	}

	.row{
		padding-top: 211px;
		padding-bottom: 40px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	.title-con{
		margin-bottom: 2.15em;

		~ .col-lg-4{

			@media (min-width: $b3 + 1){
				
				&:nth-of-type(3n+2){
					transition-delay: 0s;
				}
	
				&:nth-of-type(3n+3){
					transition-delay: .1s;
				}
	
				&:nth-of-type(3n+4){
					transition-delay: .2s;
				}
			}
		}
	}


	.col-lg-4{

		@media (min-width: $b3 + 1){
			
			+ .col-lg-4{
				margin-top: 5.8em;
	
				+ .col-lg-4{
					margin-top: 9.9em;
					margin-top: 11.6em;
				}
			}
		}

		@media (max-width: $b3){
			
			+ .col-lg-4{
				margin-top: 3em;
			}
		}
		
		&.green{

			.inner{
				border-left: 7px solid $green;
			}

			h3{

				em{
					color: $green;
				}
			}

			.link{
				color: $green;
			}

			.corner{
				background-image: url(../images/corner-g.svg);
				
			}
		}

		&.orange{

			.inner{
				border-left: 7px solid $orange;
			}

			h3{

				em{
					color: $orange;
				}
			}

			.link{
				color: $orange;
			}

			.corner{
				background-image: url(../images/corner-o.svg);
				
			}
		}

		&.red{

			.inner{
				border-left: 7px solid $red;
			}

			h3{

				em{
					color: $red;
				}
			}

			.link{
				color: $red;
			}

			.corner{
				background-image: url(../images/corner-r.svg);
				
			}
		}

		.inner{
			background-color: $white;
			padding: 54px 49px 130px 42px;
			position: relative;
			box-shadow: 0 0 40px rgba(black, .7);

			@media (max-width: $b2){
				padding: 54px 16px 130px 16px;
			}

			@media (max-width: $b3){
				min-height: 0;
				padding: 35px 26px 130px 26px;
			}

			*{
				color: #1D3A5C;
			}

			h3{
				@include line-height(41,43.5047);
				margin-bottom: 1.1em;

				@media (max-width: $b2){
					
					@media (min-width: $b3 + 1){
						@include font-size(26);
					}
				}
			}

			.text{
				letter-spacing: .01em;
				@include font-size(21);
				@include line-height(21,31.5047);

				@media (max-width: $b2){
					@include font-size(19);
				}
			
				@media (max-width: $b3){
					@include font-size(16);
				}
			}

			.corner{
				position: absolute;
				right: 0;
				bottom: 0;
				width: 29.5%;
				padding-top: 32.5%;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 100% 100%;

				@media (max-width: $b3){
					width: 69px;
					padding-top: 76px;
				}
			}

			.link{
				position: absolute;
				left: 47px;
				bottom: 89px;
				@include font-size(19);
				padding-bottom: .2em;
				border-bottom: 1px solid #1D3A5C;
				font-family: $alt-font;
				font-weight: 700;
				letter-spacing: .02em;

				@media (max-width: $b2){
					@include font-size(16);
					left: 16px;
				}
			
				@media (max-width: $b3){
					@include font-size(13);
					left: 26px;
				}

				&:hover,
				&:focus-visible{
					color: #1D3A5C;
				}
			}
		}
	}
}

.case-hero-section{
	text-align: center;

	&:after{
		top: auto;
		bottom: 0;
		transform: none;
		background-position: 0 100%;
	}

	h6{
		color: white;
		text-transform: none;
		letter-spacing: .02em;
		margin-bottom: 3.35em;

		a{

			&:hover,
			&:focus-visible{
				color: white;
			}
		}

		span{
			display: inline-block;
			margin-right: .3em;
			margin-left: .3em;
		}
	}

	.row{
		padding-top: 175px;
	}
}

.join-hero-section{
	border-bottom: 1px solid rgba(white, .5);
	
	.container{
		max-width: 1300px;
		position: relative;

		.back-circle{

			&.c-o{
				width: 217.5805px;
				height: 217.5805px;
				bottom: 57%;
				left: 91.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-g{
				width: 257.3342px;
				height: 257.3342px;
				bottom: 21.8%;
				left: -25%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-y{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 5.8%;
				left: 87%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 227px;
		padding-bottom: 86px;

		@media (max-width: $b3){
			padding-top: 140px;
		}
	}

	.col-md-8{

		@media (min-width: $b3 + 1){
			flex: 0 0 70%;
			max-width: 70%;
		}

		h1{
			margin-bottom: 1.06em;
		}

		h3{
			@include line-height(41,43.5047);
			margin-bottom: .8em;
		}
	}

	.col-md-4{
		position: relative;

		@media (min-width: $b3 + 1){
			flex: 0 0 30%;
			max-width: 30%;
		}

		.back-circle{

			&:nth-of-type(1){
				width: 333px;
				max-width: 333px;
				height: 333px;
				bottom: 58.2%;
				left: -2.3%;

				@media (max-width: $b3){
					position: static;
					margin: 0 auto;
					display: none;
				}
			}

			&:nth-of-type(2){
				width: 218px;
				max-width: 218px;
				height: 218px;
				bottom: 18.7%;
				left: 44%;

				@media (max-width: $b3){
					position: static;
					margin: -1em auto 0;
					display: none;
				}
			}
		}
	}
}

.join-section + .sign-up-section{
	display: none;
}

.join-section{

	.container{
		max-width: 1300px;
		position: relative;

		.back-circle{

			&.c-r{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 20px;
				left: -18.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 90px;

		@media (max-width: $b3){
			padding-top: 80px;
		}
	}

	.title-con{
		margin-bottom: 1.3em;

	}

	.i-item{
		margin-bottom: 2.2em;

		.inner{
			background-color: white;
			border-radius: 9px;
			display: flex;
			flex-wrap: wrap;
			padding: 50px 50px 52px;
			justify-content: space-between;
			box-shadow: 0 0 40px rgba(black, .75);

			@media (max-width: $b3){
				padding: 26px 26px 26px;
			}
		}

		.title-side{
			width: 30%;

			@media (max-width: $b3){
				width: 100%;

				.btn-con{
					display: none;
				}
			}
		}

		.text-side{
			width: 65%;

			@media (max-width: $b3){
				width: 100%;
			}

			@media (min-width: $b3 + 1){
				
				.btn-con{
					display: none;
				}
			}
		}

		*{ 
			color: $blue-dark;
		}

		h3{
			@include line-height(41,43.5047);
		}

		p{
			color: $grey;
			margin-bottom: 0;
		}

		.btn-con{
			margin-top: 1.5em;
			margin-bottom: -.2em;

			@media (max-width: $b3){
				margin-bottom: 1.5em;
			}

			.btn{
				padding: 9.8px 30px;
				font-size: 1.7rem!important;

				svg{
					margin: 12px -3px 12px 11px;
					width: 10px;
				}
			}
		}
	}

	.btn-con-center{
		margin-top: 2.4em;
		margin-bottom: 4.7em;

		@media (max-width: $b3){
			margin-bottom: 3.6em;
		}
	}
}

.news-single-hero-section,
.join-single-hero-section{

	&:after{
		background-position: 0 100%;
	}

	.container{
		position: relative;

		.back-circle{

			&.c-g{
				width: 257.3342px;
				height: 257.3342px;
				bottom: 16%;
				left: 92%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-b{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 8%;
				left: -19.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 227px;
		padding-bottom: 146px;
		text-align: center;

		@media (max-width: $b3){
			padding-top: 140px;
			padding-bottom: 140px;
		}
	}

	h1{
		margin-bottom: .9em;

		span{
			color: $blue;
		}
	}

	h6{
		text-transform: none;
		color: white;
		letter-spacing: .02em;
	}
}

.news-single-hero-section{

	.row{
		padding-top: 237px;
		padding-bottom: 136px;

		@media (max-width: $b3){
			padding-top: 140px;
			padding-bottom: 140px;
		}
	}

	h1{
		width: 880px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 0.79em;
	}

	h6{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		@media (max-width: $b3){

			span{
				width: 100%;
			}
		}

		.cat{
			display: inline-flex;
			align-items: center;
			padding: 18.8px 25px;
			font-size: 1.6rem!important;
			letter-spacing: .02em;
			text-decoration: none!important;
			line-height: 1.2em;
			font-weight: 700;
			text-decoration: none;
			text-align: center;
			box-shadow: none;
			font-family: $alt-font;
			position: relative;
			border-radius: 35px;
			transition: $time;
			min-width: 131px;
			justify-content: center;
			margin-left: 1em;

			@media (max-width: $b3){
				min-width: 0;
				padding: 14px 15px;
				font-size: 1.2rem!important;
				margin-left: 0em;
				margin-top: .5em;
			}
		}
	}
}

.service-hero-section{
	background: linear-gradient(to bottom, rgba(0,83,161,1) 0%, rgba(49,39,130,1) 65%, rgba(49,39,130,1) 86%, rgba(0,83,161,1) 100%);
	
	.container{
		position: relative;

		.back-circle{

			&.c-o{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 38%;
				left: 55.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;

				@media (max-width: 1024px){
					width: 292.3381px;
					height: 292.3381px;
					left: 44.5%;
				}
			}

			&.c-r{
				width: 292.3381px;
				height: 292.3381px;
				bottom: 47%;
				left: 95%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;

				@media (max-width: 1024px){
					left: 85%;
				}
			}

			&.c-y{
				width: 215.3057px;
				height: 215.3057px;
				bottom: 14%;
				left: 104%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 203px;
		padding-bottom: 262px;

		@media (max-width: $b3){
			padding-bottom: 80px;
		}
	}

	h1{
		margin-bottom: 0.435em;
	}

	h3{
		@include line-height(41,51.5047);
	}
}

.news-content-section,
.join-content-section{
	border-bottom: 1px solid rgba(white, .5);

	.container{
		position: relative;
		max-width: 954px;

		@media (max-width: 1060px){
			
			@media (min-width: $b3 + 1){
				max-width: calc(100% - 106px);
			}
		}

		.back-circle{

			&.c-y{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 100px;
				left: 107%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.social-menu{
		position: absolute;
		top: 0;
		left: -134px;
		width: 61px;

		@media (max-width: 1268px){
			left: -54px;
		}

		@media (max-width: 1112px){
			left: -35px;
		}

		@media (max-width: $b3){
			position: relative;
			left: auto;
			width: 100%;
			justify-content: center;
		}

		a{
			line-height: 61px;
			height: 61px;
			min-width: 61px;
			border-radius: 61px;
			background-color: white;
			color: $blue-gradient2;

			@media (min-width: $b3 + 1){
				margin: 0 0 1.01em;
			}

			@media (max-width: $b3){
				margin: 0 4px;
				line-height: 45px;
				height: 45px;
				min-width: 45px;
				border-radius: 45px;
			}

			i{
				line-height: 59px;
				min-width: 59px;

				@media (max-width: $b3){
					line-height: 43px;
					min-width: 43px;
				}
			}
		}
	}

	.row{
		padding-bottom: 127px;

		@media (max-width: $b3){
			padding-bottom: 80px;
		}
	}

	ul,
	ol,
	p{

		+ h3{
			margin-top: 1.8em;
		}

		+ h2{
			margin-top: 1.83em;
		}

		a{
			font-weight: 600;
		}
	}

	h3{
		@include line-height(41,43.5047);
	}

	h2{
		margin-bottom: .32em;
	}

	.ready{

		h2{
			margin-top: 1.83em;
		}
		
	}
}

.news-content-section{

	.row{
		padding-bottom: 81px;
	}

	h3{
		margin-bottom: .8em;
	}

}

.news-hero-section{

	&:after{
		background-position: 0 100%;
	}

	&.search{

		.row{
			padding-top: 237px;
			padding-bottom: 88px;

			@media (max-width: $b3){
				padding-top: 140px;
			}
		}
	}
	
	.container{
		position: relative;

		.back-circle{

			&.c-g{
				width: 257.3342px;
				height: 257.3342px;
				bottom: 16%;
				left: 92%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}

			&.c-b{
				width: 375.6115px;
				height: 375.6115px;
				bottom: 8%;
				left: -19.5%;
				filter: blur(6px);
				animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
			}
		}
	}

	.row{
		padding-top: 227px;
		padding-bottom: 96px;
		text-align: center;

		@media (max-width: $b3){
			padding-top: 140px;
		}
	}

	h1{
		margin-bottom: .3em;

		span{
			color: $blue;
		}
	}

	h6{
		text-transform: none;
		color: white;
		letter-spacing: .02em;
	}

	.filter-con{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-top: 2.4em;

		a{
			display: inline-flex;
			align-items: center;
			padding: 18.8px 33px;
			font-size: 1.6rem!important;
			letter-spacing: .02em;
			text-decoration: none!important;
			line-height: 1.2em;
			font-weight: 700;
			text-decoration: none;
			text-align: center;
			cursor: pointer;
			box-shadow: none;
			font-family: $alt-font;
			position: relative;
			border-radius: 35px;
			transition: $time;
			margin: 0 12px 24px;

			&.active{
				pointer-events: none;
				background-color: transparent!important;
			}

			&:hover,
			&:focus-visible{
				opacity: .75;
			}
		}
	}
}

.news-section{
	padding-bottom: 126px;

	@media (max-width: $b3){
		padding-bottom: 80px;
	}

	.search &{
		padding-bottom: 53px;

		.container{
			position: relative;
	
			.back-circle{

				&.c-r{
					width: 375.6115px;
					height: 375.6115px;
					bottom: -310px;
					left: -20%;
					filter: blur(6px);
					animation: blur+random(5) (random(4) + 1)+s (random(3) + 1)+s infinite linear;
				}
			}
		}

		.row{
			justify-content: center;
		}
	}

	@media (min-width: $b3 + 1){
		
		.col-12,
		.container{
			padding-right: 30px;
			padding-left: 30px;
		}

		.row{
			margin-right: -30px;
			margin-left: -30px;
		}
	}

	&.single{
		padding-bottom: 77px;

		.row{
			padding-top: 135px;

			@media (max-width: $b3){
				padding-top: 80px;
			}
		}

		.title-con{
			margin-bottom: 1.3em;
		}
	}

	.container{
		max-width: 1278px;
	}

	.btn-con{
		margin-top: .1em;
	}

	#loadMoreCon{

		&.no-more{
			display: none;
		}

		&.loading{
			opacity: .5;
			pointer-events: none;
		}
	}
}

.s-item,
.n-item{
	margin-bottom: 3.4em;

	.inner{
		background-color: white;
		border-bottom: 8px solid $blue-dark;
		border-radius: 5px;
		padding: 49px 49px 100px;
		min-height: 369px;
		position: relative;
		box-shadow: 0 0 40px rgba(black, .75);

		@media (max-width: $b3){
			min-height: 0px;
			padding: 26px 26px 120px;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 5px;
			z-index: 2;

			&:hover,
			&:focus-visible{
				
				~ h3{
					color: $blue;
				}
			}
		}

		h3{
			@include line-height(41,43.5047);
			transition: $time;
		}

		*{
			color: $blue-dark;
		}

		.time{
			position: absolute;
			left: 49px;
			bottom: 46px;
			font-weight: 600;
			font-family: $alt-font;
			@include font-size(19);
			line-height: 50px;

			@media (max-width: $b2){
				@include font-size(16);
			}
		
			@media (max-width: $b3){
				@include font-size(13);
				left: 26px;
			}
		}
	}

	.cat{
		display: inline-flex;
		align-items: center;
		padding: 16.8px 25px;
		font-size: 1.4rem!important;
		letter-spacing: .02em;
		text-decoration: none!important;
		line-height: 1.2em;
		font-weight: 700;
		text-decoration: none;
		text-align: center;
		cursor: pointer;
		box-shadow: none;
		font-family: $alt-font;
		position: relative;
		border-radius: 35px;
		transition: $time;
		position: absolute;
		right: 46px;
		bottom: 49px;

		@media (max-width: $b3){
			right: 26px;
			padding: 14px 15px;
			font-size: 1.2rem!important;
		}
	}
}

.s-item{
	margin-bottom: 2.9em;

	.inner{
		border-bottom: 8px solid #66a5da;
		min-height: 262px;
		padding: 49px 49px 49px;

		@media (max-width: $b3){
			min-height: 0;
			padding: 36px 26px 26px;
		}
	}
}

.search-over.search-over{
	position: fixed;
	display: flex;
	height: 100vh;
	height: 100dvh;
	width: 100%;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	background-color: rgba(#1b1b1b, .99);
	z-index: 49;
	opacity: 0;
	visibility: hidden;

	.search-open &{
		opacity: 1;
		visibility: visible;
		transition: $time;
	}

	form{
		width: 695px;
		max-width: calc(100% - 82px);
		position: relative;

		@media (max-width: $b3){
			max-width: calc(100% - 52px);
		}

		::-webkit-input-placeholder {
			@include font-size(41);
			letter-spacing: .01em;
			color: white;
			font-family: $alt-font;
			font-weight: 700;

			@media (max-width: $b2){
				@include font-size(32);
			}

			@media (max-width: $b3){
				@include font-size(19);
			}
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			@include font-size(41);
			letter-spacing: .01em;
			color: white;
			font-family: $alt-font;
			font-weight: 700;

			@media (max-width: $b2){
				@include font-size(32);
			}

			@media (max-width: $b3){
				@include font-size(19);
			}
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(41);
			letter-spacing: .01em;
			color: white;
			font-family: $alt-font;
			font-weight: 700;

			@media (max-width: $b2){
				@include font-size(32);
			}

			@media (max-width: $b3){
				@include font-size(19);
			}
		}
		
		:-ms-input-placeholder {
			@include font-size(41);
			letter-spacing: .01em;
			color: white;
			font-family: $alt-font;
			font-weight: 700;

			@media (max-width: $b2){
				@include font-size(32);
			}

			@media (max-width: $b3){
				@include font-size(19);
			}
		}

		[type="text"]{
			margin: 0;
			background-color: transparent;
			padding: 21px 40px 21px 0;
			@include font-size(41);
			letter-spacing: .01em;
			color: white;
			font-family: $alt-font;
			font-weight: 700;
			border: none;
			border-bottom: 1px solid white!important;

			&:focus-visible{
				border-bottom: 1px solid white!important;
			}

			@media (max-width: $b2){
				@include font-size(32);
			}

			@media (max-width: $b3){
				@include font-size(19);
				padding: 15px 28px 15px 0;
			}
		}

		[type="submit"]{
			position: absolute;
			top: 50%;
			right: 6px;
			transform: translateY(-50%);
			margin: 0;
			border-radius: 0;
			width: 27.1516px;
			height: 27.1516px;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;

			@media (max-width: $b3){
				right: 3px;
				width: 21.05px;
				height: 21.05px;
			}
			
			svg{
				width: 27.1516px;
				margin: 0;
				transform: scale(-1) rotate(-90deg);
			}

			&:hover,
			&:focus-visible{
				background-color: transparent;
				opacity: .5;

				svg{

					*{
						fill: white!important;
					}
				}
			}
		}
	}
}

.spacer-section{

	&.spacer-50{
		height: 50px;

		@media (max-width: $b3){
			height: 25px;
		}
	}

	&.spacer-100{
		height: 100px;

		@media (max-width: $b3){
			height: 50px;
		}
	}

	&.spacer-150{
		height: 150px;

		@media (max-width: $b3){
			height: 75px;
		}
	}
}

@media (max-width: $b3){
	
	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 26px;
		padding-left: 26px;
	}

	.row{
		margin-right: -26px;
		margin-left: -26px;
	}
}