//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -20px;
	max-width: calc(100% + 20px + 20px);
	flex-wrap: wrap;

	.btn{
		margin: 0 20px 12px;

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}

		@media (min-width: $b3 + 1){
			
			&.big:first-of-type:not(:last-of-type){
				margin-left: -8px;
			}
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
	
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	padding: 15.8px 30px;
	font-size: 1.9rem!important;
	letter-spacing: .02em;
	text-decoration: none!important;
	line-height: 1.2em;
	border: 1px solid $white;
	color: $white!important;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $alt-font;
	position: relative;
	border-radius: 35px;
	transition: $time;
	background-color: transparent;

	svg{
		margin: 12px 5px 12px 20px;

		*{
			fill: white!important;
		}
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $white;
		border-color: $white;
		color: $blue-dark!important;

		svg{

			*{
				fill: #2F4858!important;
			}
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{

	&.white{
		background-color: $white;
		border-color: $white;
		color: $blue-dark!important;

		svg{

			*{
				fill: $blue-dark!important;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $white;
			color: $white!important;

			svg{

				*{
					fill: $white!important;
				}
			}
		}
	}

	&.green{
		background-color: $green;
		border-color: $green;
		color: $white!important;

		svg{

			*{
				fill: $white!important;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $blue-dark;
			border-color: $blue-dark;
			color: $white!important;

			svg{

				*{
					fill: $white!important;
				}
			}
		}
	}

	&.big{
		padding: 30px 31px;
		border-radius: 21px;
		line-height: 1.2;
	}

	&.small{
		padding: 6px 10px;
		font-size: 11px!important;
		font-size: 1.1rem!important;
	}
}

