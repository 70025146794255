//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	// .home &{
	// 	background-color: transparent;
	// }

	@media (max-width: 1200px){
		z-index: 111;
		margin-bottom: -1px;
	}

	.scroll-up &,
	.scroll-down &{
		background-color: #0054a1;

		.menu-top-outer{
			padding-top: 14px;
			padding-bottom: 14px;
			
			@media (max-width: 1200px){
				padding-top: 14px;
				padding-bottom: 14px;
			}
		}
	}

	.scroll-up &{
		transform: translateY(0%);
	}

	.scroll-down &{
		transform: translateY(-100%);
	}

	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: 1200px){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: 1200px + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	margin: 3px 0 0;
	transition: $time;
	z-index: 1;
	width: 186.0295px;
	max-width: 186.0295px;
	transition: 0s;
	overflow: hidden;

	@media (max-width: $b4){
		width: 160.424px;
		max-width: 160.424px;
	}

	svg,
	img{
		display: block;
		width: 100%;
	}
}

.menu-top-outer{
	transition: $time;
	padding-top: 39px;
	padding-bottom: 39px;
	
	@media (max-width: 1200px){
		padding-top: 14px;
		padding-bottom: 14px;
	}
	
	.container{
		
	}
 	
	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.right,
	.left{
		display: flex;
		align-items: center;
		min-width: 187px;

		@media (max-width: $b4){
			min-width: 160.424px;
		}
	}

	.left{
		justify-content: flex-start;
	}

	.right{
		justify-content: flex-end;

		@media (max-width: 1200px){
			min-width: 0;

			.btn{
				display: none;
			}
		}
	}

	.search-btn{
		margin-left: 9px;
		height: 21.05px;
		width: 21.05px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		@media (min-width: 1300px + 1){
			margin-left: 21.1px;
		}

		@media (max-width: 1300px){

			@media (min-width: 1200px + 1){
				margin-left: 7px;
			}
		}

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 33.2059px;
			max-width: 33.2059px;
			height: 3px;
			background-color: white;
			transform: translate(-50%, -50%) rotate(45deg);
			display: block;
			opacity: 0;
			visibility: hidden;
		}

		&:after{
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		.search-open &{

			svg{
				opacity: 0;
				visibility: hidden;
			}

			&:before,
			&:after{
				opacity: 1;
				visibility: visible;
			}
		}

		&:hover,
		&:focus-visible{
			opacity: .5;
		}
	}
}

.menu-bottom-outer{
	
	// .searchform{
	// 	position: relative;

	// 	@media (max-width: 1200px){
	// 		margin-bottom: 128px;
	// 	}

	// 	::-webkit-input-placeholder {
	// 		@include font-size(20);
	// 		color: rgba(white,.78);
	// 		font-weight: 400;

	// 		@media (max-width: 1200px){
	// 			@include font-size(14);
	// 		}
	// 	}
		 
	// 	:-moz-placeholder { /* Firefox 18- */
	// 		@include font-size(20);
	// 		color: rgba(white,.78);
	// 		font-weight: 400;

	// 		@media (max-width: 1200px){
	// 			@include font-size(14);
	// 		}
	// 	}
		
	// 	::-moz-placeholder {  /* Firefox 19+ */
	// 		@include font-size(20);
	// 		color: rgba(white,.78);
	// 		font-weight: 400;

	// 		@media (max-width: 1200px){
	// 			@include font-size(14);
	// 		}
	// 	}
		
	// 	:-ms-input-placeholder {
	// 		@include font-size(20);
	// 		color: rgba(white,.78);
	// 		font-weight: 400;

	// 		@media (max-width: 1200px){
	// 			@include font-size(14);
	// 		}
	// 	}

	// 	input{
	// 		@include font-size(20);
	// 		color: white;
	// 		font-weight: 400;
	// 		background-color: #2B414F;
	// 		border: none;
	// 		padding: 22px 90px 22px 28px;

	// 		@media (max-width: 1200px){
	// 			@include font-size(14);
	// 			padding: 20px 80px 20px 16px;
	// 		}
	// 	}

	// 	button{
	// 		position: absolute;
	// 		top: 0;
	// 		right: 0;
	// 		background-image: url(../images/search.svg);
	// 		background-repeat: no-repeat;
	// 		margin: 0;
	// 		background-color: $blue-medium;
	// 		border: none;
	// 		width: 70px;
	// 		height: 70px;
	// 		padding: 0;
	// 		min-width: 70px;
	// 		background-position: 50% 50%;

	// 		@media (max-width: 1200px){
	// 			width: 58.2px;
	// 			height: 58.2px;
	// 			min-width: 58.2px;
	// 			background-size: 21px auto;
	// 		}
	// 	}
	// }
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	margin: 6px auto 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: 1200px){
		margin: 0 0 0 auto;
	}
}

@media (min-width: 1200px + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: rgba($white,.5);
		}
	}

	#mainMenu .main-menu .sub-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current-menu-parent,
	#mainMenu .main-menu .sub-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .sub-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $blue;
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0;
	padding: 0;
	@include font-size(0);
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: 1200px){
		position: fixed;
		background-color: $grey-dark;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		text-align: left;
		align-items: stretch;
		padding: 82px 26px 10px;
		flex-wrap: nowrap;
		overflow: auto;
		pointer-events: none;
		opacity: 0;
		visibility: hidden;
		transition: $time;

		.menu-open &{
			pointer-events: auto;
			opacity: 1;
			visibility: visible;
	
			&:after{
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				height: 82px;
				background-color: $grey-dark;
				z-index: 2;
			}
		}
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		flex-direction: column;
		text-align: left;
		
		@media (min-width: 1200px + 1){
			
			&.mob-menu-only{
				display: none;
			}
		}

		@media (max-width: 1200px){
			align-items: flex-start;
		}
		
		// &:last-of-type{
		// 	margin-right: 0;
		// }

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		@media (min-width: 1200px + 1){

			&:hover,
			&:focus-visible{
	
				> a{
					color: rgba($white,.5);

					&:after{
						opacity: .5;
					}
				}
			}
		}

		&.menu-item-has-children{
			background-repeat: no-repeat;
			position: relative;
			flex-wrap: wrap;

			> a{
				position: relative;
				display: flex;
				align-items: center;

				&:after{
					content: '';
					width: 10.5342px;
					height: 5.9746px;
					transition: $time;
					display: block;
					border-radius: 0px;
					border: none;
					background-image: url(../images/menu-arrow.svg);
					background-repeat: no-repeat;
					margin-left: 5px;
					margin-top: 5px;
	
					@media (max-width: 1200px){
						top: 21px;
						position: absolute;
						left: calc(100% + 10px);
						pointer-events: none;
					}
				}
			}

			@media (max-width: 1200px){
				
				&.open{

					// &:before{
					// 	transform: translateY(-50%) rotate(0deg);
					// }
	
					// .sub-menu{
					// 	pointer-events: auto!important;
					// }

					> a{

						&:after{
							transform: rotate(180deg);
						}
					}
	
					> .sub-menu{
						max-height: 1000px;
						position: relative;
						pointer-events: auto;
	
						&:after{
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							max-width: 100%;
							opacity: .4;
							visibility: visible;
						}
					}
				}
			}
			
			@media (min-width: 1200px + 1){

				&:hover,
				&:focus-visible{

					// > a{
					// 	color: rgba($white,.5);
					// }

					> ul{
						opacity: 1;
						visibility: visible;
						transition: $time;
						pointer-events: auto;
						z-index: 1;
					}
				}
			}

			.sub-menu{
				position: absolute;
				opacity: 1;
				visibility: visible;
				background-color: transparent;
				transform: none;
				width: 220px;
				max-width: 220px;
				left: -14px;
				margin-bottom: 0;
				counter-reset: section;
				background-color: $white;
				top: 100%;
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
				border-radius: 4px;
				padding: 17px 0;

				@media (max-width: 1200px){
					position: relative;
					opacity: 1;
					visibility: visible;
					max-height: 0;
					background-color: transparent;
					pointer-events: auto;
					overflow: hidden;
					padding: 0;
					top: 0;
					left: 0;
				}

				li{
					
					&:last-of-type{
						border-bottom: none;
					}
				}

				a{
					@include font-size(17);
					@include line-height(17,20);
					font-family: $main-font;
					color: $black;
					padding: 6.5px 15px 6.5px 29px;

					@media (min-width: 1200px + 1){
						font-family: $alt-font;
						font-weight: 700;
						color: $blue-dark;

						&:hover,
						&:focus-visible{
							color: $blue;
						}
					}

					@media (max-width: 1200px){
						@include font-size(21);
						font-family: $alt-font;
						font-weight: 700;
						color: rgba(white, .5);
					}

					@media (max-width: 1200px){
						padding: 6.5px 0px 6.5px;
					}
				}
			}
		}

		> a{
			@include font-size(21);
			line-height: 1.2;
			letter-spacing: .02em;
			width: auto;
			color: white;
			text-decoration: none!important;
			font-weight: 600;
			position: relative;
			font-family: $alt-font;
			position: relative;
			padding: 5px 14.1px 5px;
			padding: 22px 14.1px 22px;
			z-index: 2;
			
			@media (min-width: 1300px + 1){
				padding: 22px 21.1px 22px;
			}

			@media (max-width: 1300px){

				@media (min-width: 1200px + 1){
					padding: 22px 7px 22px;
				}
			}

			@media (min-width: 1200px + 1){

				&:hover,
				&:focus-visible{
					color: rgba($white,.5);
				}
			}

			@media (max-width: 1200px){
				@include font-size(28);
				@include line-height(23,42.4276);
				letter-spacing: .04em;
				padding: 1px 0;
			}
		}
	}
}

.single-case-studies,
.post-type-archive-case-studies,
.tax-case-studies-type{

	@media (min-width: 1200px + 1){
		
		ul.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu #menu-item-414{

			&:hover,
			&:focus-visible{

				> a{
					color: rgba(white,.5);
				}
				
			}

			> a{
				color: white;

				&:hover,
				&:focus-visible{
					color: rgba(white,.5);
				}
			}

		}

		ul.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu #menu-item-411{
			
			> a{
				color: rgba(white,.5);
			}
		}
	}
}

.single-join,
.post-type-archive-join{

	@media (min-width: 1200px + 1){
		
		ul.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu #menu-item-414{

			&:hover,
			&:focus-visible{

				> a{
					color: rgba(white,.5);
				}
				
			}

			> a{
				color: white;

				&:hover,
				&:focus-visible{
					color: rgba(white,.5);
				}
			}

		}

		ul.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu #menu-item-415{
			
			> a{
				color: rgba(white,.5);
			}
		}
	}
}

.error404,
.search-results{

	@media (min-width: 1200px + 1){
		
		ul.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu.main-menu #menu-item-414{

			&:hover,
			&:focus-visible{

				> a{
					color: rgba(white,.5);
				}
				
			}

			> a{
				color: white;

				&:hover,
				&:focus-visible{
					color: rgba(white,.5);
				}
			}

		}
	}
}