footer{
	position: relative;
	background-color: $grey-dark;

	&:after{
		content: '';
		position: absolute;
		bottom: 12px;
		left: 59.9%;
		width: 31.9%;
		height: 62.3%;
		background-size: 100% auto;
		background-image: url(../images/back-dots.svg);
		background-repeat: no-repeat;
		opacity: .05;
		pointer-events: none;

		@media (max-width: $b3){
			display: none;
		}
	}

	*{
		color: white;
	}

	.footer-top-outer{
		padding-top: 100px;

		@media (max-width: $b3){
			padding-top: 40px;
			text-align: center;
		}

		.col-12{
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width: $b3){
				justify-content: center;
			}

		}

		h2{
			margin-bottom: 0;
			margin-right: .7em;

			@media (max-width: $b3){
				margin-right: 0;
				width: 100%;
				margin-bottom: .5em;
			}
		}
	}

	.footer-middle-outer{
		padding-top: 100px;
		padding-bottom: 58px;

		@media (max-width: $b3){
			padding-top: 40px;
			padding-bottom: 40px;
			text-align: center;
		}

		.col-lg-3{
			
			@media (min-width: $b2 + 1){
				flex: 0 0 28%;
				max-width: 28%;
			}
		}

		.col-lg-6{
			
			@media (min-width: $b2 + 1){
				flex: 0 0 44%;
				max-width: 44%;
			}
		}

		h5{
			color: rgba(white, .5);
			margin-bottom: 0;

			@media (max-width: $b3){
				margin-top: .7em;
			}

			~ h5{
				margin-top: .7em;
			}
		}

		a{
			@include font-size(20);
			@include line-height(20,36);
			display: block;
			margin-bottom: .1em;

			&:hover,
			&:focus-visible{
				color: $blue!important;
			}
		}

		.social-menu{
			margin-top: .6em;
			
			@media (max-width: $b3){
				justify-content: center;
				margin-bottom: .125em;
			}
		}

		.footer-menu{
			margin: 0 ;
			@include font-size(20);
			@include line-height(20,36);

			li{
				@include font-size(20);
				@include line-height(20,36);
				
				a{
					@include font-size(20);
					@include line-height(20,36);
					font-weight: 400;
					text-decoration: none;
					color: $white;
					display: block;
					margin-bottom: .1em;

					&:hover,
					&:focus-visible{
						color: $blue!important;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		padding: 35px 0 92px;
		width: 100%;

		@media (max-width: $b2){
			padding: 0px 0 40px;
			text-align: center;

			img{
				margin: 0 1em 1em;
			}
		}

		@media (max-width: 630px){
			padding: 0px 0 84px;
		}

		.col-lg-3{
			display: flex;
			align-items: center;

			@media (min-width: $b2 + 1){
				flex: 0 0 28%;
				max-width: 28%;
			}

			@media (max-width: $b2){
				justify-content: center;
				flex: 0 0 100%;
				max-width: 100%;
			}

			img{

				&:nth-of-type(1){
					width: 50px;
					margin-right: 20px;

					@media (max-width: $b2){
						margin-right: 1em;
					}
				}

				&:nth-of-type(2){
					width: 183px;
				}
			}
		}

		.col-lg-9{

			@media (min-width: $b2 + 1){
				flex: 0 0 72%;
				max-width: 72%;
			}

			@media (max-width: $b2){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.copy{
			@include font-size(18);
			@include line-height(18,28);

			span{
				color: rgba(white, .5);
			}

			a{

				&:hover,
				&:focus-visible{
					color: $blue!important;
				}
			}
		}
		
	}
}