@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'THICCCBOI';
    src: url('../fonts/THICCCBOI-SemiBold.woff2') format('woff2'),
        url('../fonts/THICCCBOI-SemiBold.woff') format('woff'),
        url('../fonts/THICCCBOI-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'THICCCBOI';
    src: url('../fonts/THICCCBOI-Bold.woff2') format('woff2'),
        url('../fonts/THICCCBOI-Bold.woff') format('woff'),
        url('../fonts/THICCCBOI-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

//BREAK POINTS
$b1:				1224px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red-light:			#f9c6cc;
$red:				#e7334a;

$green-light:		#d3e6d0;
$green:				#64a757;

$yellow-light:		#fef5bf;
$yellow:			#fcc122;

$orange-light:		#fde5c9;
$orange:			#f6a43e;

$blue-light:		#e6eef6;
$blue:				#66a5da;
$blue-dark:			#1d3a5c;

$blue-gradient1:	#0053a1;
$blue-gradient2:	#312782;

$grey:				#4a4f54;
$grey-dark:			#1b1b1b;

$white:				#ffffff;
$black:				#000000;

$colour: 			$white;
$main-bg-colour:	$blue-gradient1;

$font-inter:		'Inter', serif;
//regular - 400

$font-thiccc:		'THICCCBOI', serif;
//semin - 600
//bold - 700

$main-font:			$font-inter;
$alt-font:			$font-thiccc;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	82px !default;

//!!PAGE WIDTH
$base-width:		1224px;

//!!SITE TRANSITIONS
$time:				.25s;